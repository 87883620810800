

















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ThirdPartySystemCard from "@/components/third-party-system/ThirdPartySystemCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IThirdPartySystem, ThirdPartySystem } from "@/models/third-party-system.entity";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    ThirdPartySystemCard,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ThirdPartySystemDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get thirdPartySystemId(): string {
    return this.$route.params.thirdPartySystemId;
  }

  isLoading = false;

  thirdPartySystem: IThirdPartySystem | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const thirdPartySystem =
      ThirdPartySystemModule.maps.id?.get(this.thirdPartySystemId)[0] ??
      new ThirdPartySystem({
        id: this.thirdPartySystemId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await thirdPartySystem.fetch();
      this.thirdPartySystem = thirdPartySystem;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
