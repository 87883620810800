















































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import InstructionCard from "@/components/instructions/InstructionCard.vue";
import { $t } from "@/lib/utility/t";

@Component({ components: { InstructionCard } })
export default class DropField extends Vue {
  @Prop()
  iconColor?: string[];

  @Prop()
  icon: any;

  @Prop()
  text: any;

  @Prop({ default: () => $t("components.DropField.upload") })
  buttonText!: string;

  @Prop()
  file?: File;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: false })
  isUploaded!: boolean;

  @Prop()
  image!: string;

  @Prop({ default: false })
  hasInstructions!: boolean;

  @Prop({ default: () => $t("components.DropField.instructions") })
  instructionTitle!: string;

  @Prop({ default: () => $t("components.DropField.exampleOnHowToTakePicture") })
  instructionText!: string;

  @Prop()
  instructionPictures!: string[];

  @Prop({ default: ConfigModule.color.primary })
  color!: string;

  @Ref("uploader") readonly uploader!: HTMLInputElement;
  @Ref("imageUploaded") readonly imageUploaded!: HTMLImageElement;

  isDragging = false;

  dialog = false;

  get fileTypes() {
    return ConfigModule.imageInputTypesString;
  }

  disableDialog() {
    this.dialog = false;
  }

  get url() {
    return URL.createObjectURL(this.file);
  }

  dragOver() {
    this.isDragging = true;
  }

  dragLeave() {
    this.isDragging = false;
  }

  get dragging() {
    return this.isDragging;
  }

  get getClasses() {
    return { isDragging: this.isDragging };
  }

  get currentFile() {
    return this.file;
  }

  get alt() {
    return `Upload of ${this.text}`;
  }

  upload() {
    this.uploader.click();
  }

  async onFileDrop(event: DragEvent) {
    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) {
      this.$log.info("empty, return");
      return;
    }

    const files = dataTransfer.files;
    if (files.length > 1) {
      this.$toast.error($t("components.DropField.justDropOne"));
      return;
    }
    this.handleFile(files[0]);
    this.isDragging = false;
  }

  onFileInput(event: any) {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  emitUploadedFile(file: File) {
    this.$emit("uploaded", file);
  }

  handleFile(file: File) {
    if (this.isImage(file)) {
      this.emitUploadedFile(file);
    } else {
      this.displayNotAnImage();
    }
  }
  isImage(file: File) {
    return file.type.indexOf("image/") >= 0;
  }

  displayNotAnImage() {
    this.$log.error("Not an image found");
    this.$toast.error($t("components.DropField.justImages"));
  }
}
