var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.handover
        ? _c(
            "v-container",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.measureWidth,
                  expression: "measureWidth"
                }
              ],
              ref: "container"
            },
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title,
                  breadCrumbs: _vm.breadCrumbs,
                  actions: _vm.actions
                },
                on: {
                  actionClicked: function actionClicked($event) {
                    return $event.exec()
                  }
                }
              }),
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c("table-wrapper", {
                    attrs: {
                      allItems: _vm.indexedAttachments,
                      headers: _vm.headers,
                      controlElements: _vm.controlElements,
                      sortBy: "index",
                      sortDesc: true,
                      isClickableRow: true,
                      loading: _vm.selectingAttachment
                    },
                    on: {
                      "click:row": function clickRow($event) {
                        return _vm.selectAttachment($event.document)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.index",
                        fn: function fn(_ref) {
                          var item = _ref.item
                          return [
                            item.loading
                              ? _c("v-skeleton-loader", {
                                  attrs: {
                                    type: "list-item-two-line"
                                  }
                                })
                              : _c(
                                  "v-list-item",
                                  {
                                    staticClass: "my-1",
                                    style: "width: ".concat(
                                      _vm.containerWidth -
                                        (_vm.$vuetify.breakpoint.smAndDown
                                          ? 60
                                          : 200),
                                      "px"
                                    )
                                  },
                                  [
                                    item.document
                                      ? _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass: "text-caption"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "simpleDoubleDigitDate"
                                                      )(
                                                        item.document.timestamp
                                                          .created
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.document.title) +
                                                  " "
                                              )
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-line"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.document
                                                          .description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-list-item-content",
                                          [
                                            _c("latest-entries-card-empty", {
                                              staticClass: "my-n4",
                                              staticStyle: {
                                                transform: "scale(60%)"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("latest-entries-card-empty"),
      _vm.isDocumentDialogActive && _vm.handover
        ? _c("document-selection-dialog", {
            attrs: {
              folder: "/handover/".concat(_vm.handover.id),
              isDialogActive: _vm.isDocumentDialogActive,
              partnerId: _vm.handover.partnerId,
              createActivityOnSelect: false,
              createActivityOnUpload: false
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isDocumentDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isDocumentDialogActive = $event
              },
              created: _vm.onAttachmentCreated
            }
          })
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isAttachmentRemoveDialog,
            title: _vm.$t("remove"),
            rightText: _vm.$t("remove"),
            rightLoading: _vm.isRemovingAttachmentLoading,
            rightColor: "error"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isAttachmentRemoveDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isAttachmentRemoveDialog = $event
            },
            close: _vm.abortAttachmentRemoveDialog,
            leftClick: _vm.abortAttachmentRemoveDialog,
            rightClick: _vm.saveAttachmentRemoveDialog
          }
        },
        [
          _vm.attachments[_vm.removeAttachmentIndex]
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: {
                            color: "primary"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                dark: ""
                              }
                            },
                            [_vm._v("mdi-attachment")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "text-caption"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("simpleDoubleDigitDate")(
                                  _vm.attachments[_vm.removeAttachmentIndex]
                                    .document.timestamp.created
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.attachments[_vm.removeAttachmentIndex]
                                .document.title
                            ) +
                            " "
                        )
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.attachments[_vm.removeAttachmentIndex]
                                .description
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.selectedAttachment
        ? _c(
            "side-card",
            {
              attrs: {
                absolute: true,
                width: "600px"
              },
              on: {
                close: function close($event) {
                  _vm.selectedAttachment = false
                }
              }
            },
            [
              _c("m-header", {
                attrs: {
                  title: _vm.selectedAttachment.title,
                  actions: _vm.fileActions
                },
                on: {
                  actionClicked: function actionClicked($event) {
                    return $event.exec()
                  }
                }
              }),
              _vm._v(" " + _vm._s(_vm.selectedAttachment.description) + " "),
              _c("pdf-viewer", {
                attrs: {
                  height: "500px",
                  pdf: _vm.selectedAttachment.url,
                  vertical: !_vm.isMobilePdfViewer,
                  horizontal: _vm.isMobilePdfViewer
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }