var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.title,
          subtitle: _vm.subtitle,
          chips: _vm.chips,
          breadCrumbs: _vm.breadCrumbs,
          actions: _vm.actions,
          loading: _vm.isLoading
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          }
        }
      }),
      _c(
        "m-detail-view-grid",
        {
          attrs: {
            bottomSheet: false
          },
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function fn() {
                return [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("project.ticket.assignees")))
                      ]),
                      !_vm.users
                        ? _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "paragraph"
                            }
                          })
                        : _vm.users
                        ? _c("v-select", {
                            attrs: {
                              "item-value": "id",
                              "item-text": "firstName",
                              label: _vm.$t("project.ticket.assignees"),
                              items: _vm.users,
                              multiple: "",
                              flat: "",
                              clearable: "",
                              solo: "",
                              dense: "",
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function fn(_ref) {
                                  var _vm$value$assignees, _vm$value$assignees2

                                  var item = _ref.item,
                                    index = _ref.index
                                  return [
                                    index === 0
                                      ? _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              attrs: {
                                                outlined: ""
                                              }
                                            },
                                            "v-chip",
                                            item.attrs,
                                            false
                                          ),
                                          [
                                            item.firstName && item.lastName
                                              ? _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: {
                                                      color: "primary",
                                                      small: "",
                                                      left: ""
                                                    }
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.firstName[0]
                                                          ) +
                                                          _vm._s(
                                                            item.lastName[0]
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-truncate",
                                                style:
                                                  ((_vm$value$assignees =
                                                    _vm.value.assignees) ===
                                                    null ||
                                                  _vm$value$assignees === void 0
                                                    ? void 0
                                                    : _vm$value$assignees.length) >
                                                  1
                                                    ? "max-width: 75px;"
                                                    : "max-widht: 100px"
                                              },
                                              [
                                                _c("user-name-info", {
                                                  attrs: {
                                                    text: ""
                                                      .concat(
                                                        item.firstName,
                                                        " "
                                                      )
                                                      .concat(item.lastName),
                                                    item: item
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    index === 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "grey--text "
                                          },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(
                                                  ((_vm$value$assignees2 =
                                                    _vm.value.assignees) ===
                                                    null ||
                                                  _vm$value$assignees2 ===
                                                    void 0
                                                    ? void 0
                                                    : _vm$value$assignees2.length) -
                                                    1
                                                ) +
                                                ") "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function fn(_ref2) {
                                  var item = _ref2.item
                                  return [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        item.firstName && item.lastName
                                          ? _c(
                                              "v-avatar",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color: "primary",
                                                  small: "",
                                                  size: "35px",
                                                  right: true
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.firstName[0]) +
                                                    _vm._s(item.lastName[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c("user-name-info", {
                                              attrs: {
                                                text: ""
                                                  .concat(item.firstName, " ")
                                                  .concat(item.lastName),
                                                item: item
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            " " + _vm._s(item.userName) + " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.assignees,
                              callback: function callback($$v) {
                                _vm.assignees = $$v
                              },
                              expression: "assignees"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("common.nouns.vehicle")))
                      ]),
                      _vm._l(_vm.vehicles, function(vehicle) {
                        return _c(
                          "div",
                          {
                            key: "handover-vehicle-".concat(vehicle.id)
                          },
                          [
                            vehicle.loading
                              ? _c("v-skeleton-loader", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    type: "paragraph"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function click($event) {
                                    return _vm.goToVehicle(vehicle)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: "primary",
                                      left: ""
                                    }
                                  },
                                  [
                                    _vm.isTrain
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              dark: "",
                                              dense: ""
                                            }
                                          },
                                          [_vm._v(" mdi-train ")]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              dark: "",
                                              dense: ""
                                            }
                                          },
                                          [_vm._v(" mdi-car-outline ")]
                                        )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " + _vm._s(vehicle.displayName) + " "
                                      )
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(vehicle.identificationnumber) +
                                          " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("objects.handover.date")))
                      ]),
                      _c("handover-detail-handover-date-list-item", {
                        attrs: {
                          value: _vm.value
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("common.nouns.parties")))
                      ]),
                      _vm._l(_vm.value.parties, function(company, index) {
                        return _c(
                          "v-list-item",
                          {
                            key: "users".concat(index)
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: "primary"
                                    }
                                  },
                                  [
                                    company.companyName
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(company.companyName[0]) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass: "text-caption"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "enums.HandoverCompanyTypeEnum.".concat(
                                              company.companyType
                                            )
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " + _vm._s(company.companyName) + " "
                                  )
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(" " + _vm._s(company.email) + " ")
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(" " + _vm._s(company.phone))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "context-menu",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function click($event) {
                                            return _vm.openUpdateCompanyDialog(
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("edit")))]
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function click($event) {
                                            return _vm.openRemoveCompanyDialog(
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("remove")))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            block: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.openAddCompanyDialog()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("add")))]
                      )
                    ],
                    2
                  ),
                  _vm.isDocumentDialogActive
                    ? _c("document-selection-dialog", {
                        attrs: {
                          folder: "/handover/".concat(_vm.value.id),
                          isDialogActive: _vm.isDocumentDialogActive,
                          partnerId: _vm.partnerId,
                          createActivityOnSelect: false,
                          createActivityOnUpload: false
                        },
                        on: {
                          "update:isDialogActive": function updateIsDialogActive(
                            $event
                          ) {
                            _vm.isDocumentDialogActive = $event
                          },
                          "update:is-dialog-active": function updateIsDialogActive(
                            $event
                          ) {
                            _vm.isDocumentDialogActive = $event
                          },
                          created: _vm.onAttachmentCreated
                        }
                      })
                    : _vm._e(),
                  _c(
                    "confirm-action-dialog",
                    {
                      attrs: {
                        isDialogActive: _vm.isCompanyAddDialog,
                        title: _vm.$t("common.nouns.company"),
                        rightLoading: _vm.isCompanyFormLoading,
                        rightDisabled: !_vm.isCompanyFormValid
                      },
                      on: {
                        "update:isDialogActive": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isCompanyAddDialog = $event
                        },
                        "update:is-dialog-active": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isCompanyAddDialog = $event
                        },
                        close: _vm.abortCompanyAddDialog,
                        leftClick: _vm.abortCompanyAddDialog,
                        rightClick: _vm.saveCompanyAddDialog
                      }
                    },
                    [
                      _c("company-form", {
                        on: {
                          isValid: function isValid($e) {
                            return (_vm.isCompanyFormValid = $e)
                          }
                        },
                        model: {
                          value: _vm.companyCopy,
                          callback: function callback($$v) {
                            _vm.companyCopy = $$v
                          },
                          expression: "companyCopy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "confirm-action-dialog",
                    {
                      attrs: {
                        isDialogActive: _vm.isCompanyUpdateDialog,
                        title: _vm.$t("update"),
                        rightLoading: _vm.isCompanyFormLoading,
                        rightDisabled: !_vm.isCompanyFormValid
                      },
                      on: {
                        "update:isDialogActive": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isCompanyUpdateDialog = $event
                        },
                        "update:is-dialog-active": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isCompanyUpdateDialog = $event
                        },
                        close: _vm.abortCompanyUpdateDialog,
                        leftClick: _vm.abortCompanyUpdateDialog,
                        rightClick: _vm.saveCompanyUpdateDialog
                      }
                    },
                    [
                      _c("company-form", {
                        on: {
                          isValid: function isValid($e) {
                            return (_vm.isCompanyFormValid = $e)
                          }
                        },
                        model: {
                          value: _vm.companyCopy,
                          callback: function callback($$v) {
                            _vm.companyCopy = $$v
                          },
                          expression: "companyCopy"
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function fn() {
                return [
                  _c("m-action-list", {
                    attrs: {
                      divider: true,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: function actionClicked($event) {
                        return $event.exec()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("debug", [_vm._v("value " + _vm._s(_vm.value))]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("m-navigation-list", {
                    attrs: {
                      title: "Navigation",
                      actions: _vm.navigation
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("m-quick-link-list", {
                    attrs: {
                      title: "Schnellzugriffe",
                      actions: _vm.quickLinks
                    },
                    on: {
                      actionClicked: function actionClicked($event) {
                        return $event.exec()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-n4"
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0"
                },
                [
                  _c(
                    "timeline-card",
                    {
                      attrs: {
                        outlined: "",
                        title: _vm.$t("objects.inspection.description")
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "menu",
                          fn: function fn() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: ""
                                  },
                                  on: {
                                    click: _vm.openEditDescriptionMenu
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                              ),
                              _c(
                                "confirm-action-dialog",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "objects.inspection.description"
                                    ),
                                    isDialogActive: _vm.isEditDescriptionMenu,
                                    rightLoading: _vm.isLoadingSaveDescription,
                                    leftText: _vm.$t("cancel"),
                                    rightText: _vm.$t("save"),
                                    supressKeyboardConfirm: true
                                  },
                                  on: {
                                    "update:isDialogActive": function updateIsDialogActive(
                                      $event
                                    ) {
                                      _vm.isEditDescriptionMenu = $event
                                    },
                                    "update:is-dialog-active": function updateIsDialogActive(
                                      $event
                                    ) {
                                      _vm.isEditDescriptionMenu = $event
                                    },
                                    leftClick: _vm.abortEditDescription,
                                    rightClick: _vm.saveEditDescription
                                  }
                                },
                                [
                                  _c("template-editor", {
                                    ref: "templateEditor",
                                    staticStyle: {
                                      height: "350px"
                                    },
                                    attrs: {
                                      readOnly: false,
                                      label: _vm.$t(
                                        "objects.inspection.description"
                                      )
                                    },
                                    model: {
                                      value: _vm.descriptionCopy,
                                      callback: function callback($$v) {
                                        _vm.descriptionCopy = $$v
                                      },
                                      expression: "descriptionCopy"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("template-editor", {
                        key: _vm.value.description,
                        attrs: {
                          height: "100%",
                          value: _vm.value.description,
                          readOnly: true,
                          outlined: false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.activitySource && _vm.partner
            ? _c("activity-card", {
                key: "activity-card" + _vm.partnerId,
                attrs: {
                  partner: _vm.partner,
                  user: _vm.value,
                  source: _vm.activitySource,
                  config: {
                    comment: true,
                    email: _vm.isMail
                  },
                  mail: _vm.mails
                }
              })
            : _vm._e(),
          _c("create-handover-finding-dialog", {
            ref: "createHandoverFindingDialog",
            attrs: {
              vehicles: _vm.vehicles,
              handover: _vm.value
            },
            on: {
              "create:inspection": _vm.openCreateInspectionDialog
            }
          }),
          _c("create-handover-snapshot-dialog", {
            ref: "createSnapshotDialog",
            attrs: {
              value: _vm.value
            }
          }),
          _c("create-agreement-dialog", {
            ref: "createAgreementDialog",
            attrs: {
              value: _vm.value
            }
          }),
          _c("tour-handover-finding-dialog", {
            ref: "tourDialog",
            attrs: {
              vehicles: _vm.vehicles,
              handover: _vm.value
            }
          }),
          _c("inspection-create-dialog", {
            ref: "inspectionCreateDialog",
            attrs: {
              handover: _vm.value
            }
          }),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isCompanyRemoveDialog,
                title: _vm.$t("remove"),
                rightText: _vm.$t("remove"),
                rightLoading: _vm.isCompanyFormLoading,
                rightColor: "error"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isCompanyRemoveDialog = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isCompanyRemoveDialog = $event
                },
                close: _vm.abortCompanyRemoveDialog,
                leftClick: _vm.abortCompanyRemoveDialog,
                rightClick: _vm.saveCompanyRemoveDialog
              }
            },
            [
              _vm.value.parties[_vm.removeCompanyIndex]
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "primary"
                              }
                            },
                            [
                              _vm.value.parties[_vm.removeCompanyIndex]
                                .companyName
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.value.parties[
                                            _vm.removeCompanyIndex
                                          ].companyName[0]
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.value.parties[_vm.removeCompanyIndex]
                                    .companyName
                                ) +
                                " "
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.value.parties[_vm.removeCompanyIndex]
                                    .email
                                ) +
                                " "
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.value.parties[_vm.removeCompanyIndex]
                                    .phone
                                )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }