































































import Card from "@/components/utility/Card.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { downloadAsXlsx, getTranslationsFromEntity } from "@/lib/download-as-xlsx";
import { ContractTypeEnum } from "@/lib/enum/contractType.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import { CreateContractDto } from "@/models/create-contract.dto";
import { MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DocumentModule } from "@/store/modules/document.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleActivityLogModule } from "@/store/modules/activity-log.store";
import DocumentFormDialog from "@/views/sign/DocumentFormDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import GoToReferenceButton from "../partner/GoToReferenceButton.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ExcelImportCreate from "../utility/ExcelImportCreate.vue";
import ContractCreateDialog from "./ContractCreateDialog.vue";
import ContractSideCard from "./ContractSideCard.vue";
import FleetContractCrudMixin from "./FleetContractCrudMixin.vue";

@Component({
  components: {
    DocumentFormDialog,
    Card,
    TableWrapper,
    GoToReferenceButton,
    ContractCreateDialog,
    ContractSideCard,
    ConfirmActionDialog,
    ExcelImportCreate
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class FleetVehicleDetailContractTable extends mixins(FleetContractCrudMixin) {
  @Prop()
  vehicle!: IVehicle;

  loading = false;

  selected: MrfiktivLeasingContractGen[] = [];

  contractToDelete: MrfiktivLeasingContractGen | null = null;

  isConfirmDeleteContractDialogActive = false;

  fileUploadContract: MrfiktivLeasingContractGen | null = null;

  sideCardContract: MrfiktivLeasingContractGen | null = null;

  createContractDialog = false;

  isDeleteContractLoading = false;

  loadingDocumentSuggestions = false;

  vehicleDocuments: ISignDocument[] = [];

  download() {
    downloadAsXlsx(this.contracts, getTranslationsFromEntity(this.createDto));
  }

  readonly createDto = CreateContractDto;

  readonly baseData = {
    partnerId: PartnerModule.partner._id,
    vehicleId: this.vehicle.id
  };

  get contracts(): MrfiktivLeasingContractGen[] {
    return this.vehicle.contracts as MrfiktivLeasingContractGen[];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.openContractSideCard,
        text: this.i18n["openNew"]
      },
      {
        icon: "mdi-trash-can",
        action: this.startDeleteContractDialog,
        loading: this.isDeleteContractLoading,
        text: this.i18n["delete"]
      }
    ];
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({ text: this.i18n["title"], value: "title", width: "150px" });

    if (Object.values(ContractTypeEnum).length > 1) {
      headers.push({ text: this.i18n["contractType"], value: "contractType", width: "150px" });
    }

    headers.push(
      { text: this.i18n["startDate"], value: "startDate", width: "150px", type: "date" },
      { text: this.i18n["endDate"], value: "endDate", width: "150px", type: "date" },
      { text: "", value: "controls", align: "end", sortable: false, width: "120px" }
    );

    return headers;
  }
  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailContractTable") || {};
  }

  get vehicleDocumentIds() {
    const documentIds: string[] = [];

    const activities = VehicleActivityLogModule.filteredAndSorted;
    for (const activity of activities) {
      if (activity.activity === ActivityTypeEnum.CREATE_DOCUMENT && activity.target?.length) {
        documentIds.push(activity.target[0].refId);
      }
    }

    return documentIds;
  }

  @Watch("vehicleDocumentIds", { deep: true })
  async loadDocuments() {
    if (!UserModule.abilities.can(ActionEnum.READ, BackendResourceEnum.DOCUMENT, this.vehicle.partnerId)) return;

    this.vehicleDocuments.splice(0);
    const promises = [];
    this.loadingDocumentSuggestions = true;

    for (const id of this.vehicleDocumentIds) {
      const document = DocumentModule.maps.id.get(id)[0];
      if (document) {
        this.vehicleDocuments.push(document);
      } else {
        promises.push(
          new SignDocument({ partnerId: this.vehicle.partnerId, id: id })
            .fetch()
            .then(d => this.vehicleDocuments.push(d))
            .catch(this.$log.error)
        );
      }
    }

    this.loadingDocumentSuggestions = false;
  }

  mounted() {
    const contractId = this.$route.query.contractId as string;

    if (contractId) {
      const contract = this.getContract(contractId);

      if (contract) {
        this.openContractSideCard(contract);
      }
    }

    this.loadDocuments();
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  startDeleteContractDialog(contract: MrfiktivLeasingContractGen) {
    this.isConfirmDeleteContractDialogActive = true;
    this.contractToDelete = contract;
  }

  addContract() {
    this.createContractDialog = true;
  }

  openContractSideCard(contract: MrfiktivLeasingContractGen) {
    if (!contract?.id) {
      return;
    }

    const location: RawLocation = {
      name: "FleetVehicleDetailTab",
      params: { partnerId: this.vehicle.partnerId, vehicleId: this.vehicle.id, tab: "contract" },
      query: { contractId: contract.id }
    };
    new GoToHelper(this.$router).setUrl(location);

    this.sideCardContract = null;
    this.$nextTick(() => {
      this.sideCardContract = contract;
    });
  }

  closeContractSideCard() {
    this.sideCardContract = null;

    const location: RawLocation = {
      name: "FleetVehicleDetailTab",
      params: { partnerId: this.vehicle.partnerId, vehicleId: this.vehicle.id, tab: "contract" }
    };
    new GoToHelper(this.$router).setUrl(location);
  }

  async onRemoveContract(contract: MrfiktivLeasingContractGen) {
    await this.removeContractById(contract.id);
  }

  getContract(contractId: string) {
    return this.vehicle.contracts?.find(contract => contract.id === contractId) ?? null;
  }

  async onUpdateContract(contract: MrfiktivLeasingContractGen) {
    await this.updateContract(this.vehicle.partnerId, this.vehicle.id, contract.id, contract);
  }

  async removeContractById(contractId: string) {
    await this.deleteContract(this.vehicle.partnerId, this.vehicle.id, contractId);
    this.isConfirmDeleteContractDialogActive = false;
    this.sideCardContract = null;
  }
}
