







import LoaderPortal from "@/components/utility/LoaderPortal.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { EventBusEnum } from "@/lib/enum/eventBus.enum";
import eventBus from "@/lib/eventBus";
import { AppColorSchema } from "@/lib/utility/AppColorSchema";
import { portalRouteConfig } from "@/router/portal.router";
import { ConfigModule } from "@/store/modules/config";
import { ExportModule } from "@/store/modules/export.store";
import { UserModule } from "@/store/modules/me-user.store";
import { NavigationModule } from "@/store/modules/navigation.store";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import { Component } from "vue-property-decorator";
import { VuexModule } from "vuex-module-decorators";
import { PartnerModule } from "../store/modules/partner";
import { UiModule } from "../store/modules/ui.store";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({ components: { LoaderPortal } })
export default class V extends ProjectInitializeListsMixin {
  get ActionEnum() {
    return ActionEnum;
  }
  get ResourceEnum() {
    return ResourceEnum;
  }

  async mounted() {
    const isDebug = this.$route.query["debug"];
    if (isDebug) {
      ConfigModule.setDebug(true);
    }

    NavigationModule.setRoutes(portalRouteConfig());
    ConfigModule.setAppContext(AppContextEnum.REPORT_PORTAL);
    ConfigModule.setPortalLogo(AssetEnum.damageReportLogoIcon);
    NavigationModule.setNavigationConfiguration({ isQuicklinks: true });

    new AppColorSchema(this.$vuetify, ConfigModule.portalReportColorSchema);

    // handle logout
    eventBus.$on("logout", () => {
      this.$router.push({ name: "Logout" });
    });

    // handle forced logout
    eventBus.$on("sessionExpired", () => {
      this.$router.push({ path: "/login/logout?reason=expired" });
    });

    // handle if user has no roles -> SPAM
    eventBus.$on(EventBusEnum.SPAM_USER_EXCEPTION, async () => {
      this.$router.push({ name: "NoRoles" });
    });

    // load some data in the background when a partner is loaded
    eventBus.$on("partnerLoaded", () => {
      const partnerId = PartnerModule.partner._id;

      // Iterate over all VuexModule and call partner change methods, e.g. `reset`.
      const stores = Object.values((this.$store as any)._modules.root._children) as VuexModule[];
      for (const store of stores) {
        // Check if a reset is defined
        if ((store as any)._rawModule._statics.reset) {
          this.$log.info("Reset", (store as any)._rawModule.name);
          store.context.dispatch("reset");
        } else {
          this.$log.info("No Reset", (store as any)._rawModule.name);
        }
      }

      this.getPartnerUsers();

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TPS, this.partnerId)) {
        const ksrSetupPromise = ExportModule.checkIsKsrSetup({ partnerId }).then(this.onKsrSetupCheckDone);
        ExportModule.handleKsrSetupCheckAsync(ksrSetupPromise);

        const daSetupPromise = ExportModule.checkIsDaSetup({ partnerId }).then(this.onDaSetupCheckDone);
        ExportModule.handleDaSetupCheckAsync(daSetupPromise);

        const pdrSetupPromise = ExportModule.checkIsPdrSetup({ partnerId }).then(this.onPdrSetupCheckDone);
        ExportModule.handlePdrSetupCheckAsync(pdrSetupPromise);
      }
    });

    try {
      await UserModule.getUserInfo();

      // Load all partners && set the first to be default
      await PartnerModule.getPartners();
      const p = PartnerModule.partnersMap.get(this.$route.params.partnerId);
      if (p) {
        PartnerModule.setPartner(p);
      } else {
        PartnerModule.setPartner(PartnerModule.partners[0]);
      }

      eventBus.$emit("partnerLoaded");
    } catch {
      this.$log.warn("User not logged in");
    } finally {
      UiModule.setLoading(false);
    }
  }

  onKsrSetupCheckDone() {
    this.$log.info(ExportModule.isKsrSetup ? "KSR is setup" : "KSR is not setup");
    ExportModule.handleKsrSetupCheckAsync(undefined);
  }

  onDaSetupCheckDone() {
    this.$log.info(ExportModule.isDaSetup ? "DA is setup" : "DA is not setup");
    ExportModule.handleDaSetupCheckAsync(undefined);
  }

  onPdrSetupCheckDone() {
    this.$log.info(ExportModule.isPdrSetup ? "Pdr is setup" : "PDR is not setup");
    ExportModule.handlePdrSetupCheckAsync(undefined);
  }

  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off("logout");
    eventBus.$off("sessionExpired");
    eventBus.$off("partnerLoaded");
  }

  get loading() {
    return UiModule.loading;
  }
}
