












import Card from "@/components/utility/Card.vue";
import { EventUIDto, IEventUIDto, RecurringEventEnum } from "@/lib/dto/event/event-ui.dto";
import { eventTemplates } from "@/lib/eventTemplates/eventTamples";
import { excelDateToJSDate } from "@/lib/excelImportTransformation/transformers";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";
import { deepCopy } from "@/lib/utility/deep-copy";
import { handleError } from "@/lib/utility/handleError";
import { ActivityLog } from "@/models/activity-log.entity";
import { MrfiktivCreateActivityLogDtoGen, MrfiktivCreateEventDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImport from "../utility/ExcelImport.vue";
import ExcelImportReport from "../utility/ExcelImportReport.vue";

@Component({
  components: { Card, ExcelImport, ExcelImportReport }
})
export default class FleetEventImportDialog extends Vue {
  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  loading = false;
  success = false;
  importedItems: IEventUIDto[] = [];
  failedImports: { vehicleId: string; error: string }[] = [];
  totalItems = 0;

  config: { [key: string]: IExcelImportConfig } = {
    vehicleId: {
      label: "vehicleId",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    [RecurringEventEnum.HU_AU_2]: {
      label: this.templateTitle(RecurringEventEnum.HU_AU_2),
      hint: this.templateDescription(RecurringEventEnum.HU_AU_2),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.HU_AU_1]: {
      label: this.templateTitle(RecurringEventEnum.HU_AU_1),
      hint: this.templateDescription(RecurringEventEnum.HU_AU_1),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.SP]: {
      label: this.templateTitle(RecurringEventEnum.SP),
      hint: this.templateDescription(RecurringEventEnum.SP),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.UVV]: {
      label: this.templateTitle(RecurringEventEnum.UVV),
      hint: this.templateDescription(RecurringEventEnum.UVV),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.TP]: {
      label: this.templateTitle(RecurringEventEnum.TP),
      hint: this.templateDescription(RecurringEventEnum.TP),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.DGUV]: {
      label: this.templateTitle(RecurringEventEnum.DGUV),
      hint: this.templateDescription(RecurringEventEnum.DGUV),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    [RecurringEventEnum.CODE_XL]: {
      label: this.templateTitle(RecurringEventEnum.CODE_XL),
      hint: this.templateDescription(RecurringEventEnum.CODE_XL),
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    }
  };

  items: MrfiktivCreateEventDtoGen[] = [];

  findtemplatelByEnum(eventEnum: RecurringEventEnum) {
    const foundTemplate = eventTemplates.filter(event => eventEnum === event.enum);
    if (foundTemplate && foundTemplate.length) {
      return foundTemplate[0];
    }
  }

  templateTitle(eventEnum: RecurringEventEnum) {
    const i18nKey = this.findtemplatelByEnum(eventEnum)?.title;
    if (i18nKey) {
      return this.$t(i18nKey).toString();
    }
    return "";
  }

  templateDescription(eventEnum: RecurringEventEnum) {
    const i18nKey = this.findtemplatelByEnum(eventEnum)?.description;
    if (i18nKey) {
      return this.$t(i18nKey).toString();
    }
    return "";
  }

  async create(items: { data: any[]; headers: any[] }) {
    this.items = [];
    this.importedItems = [];
    this.failedImports = [];

    // Calculate the expected amount of items, by enumerating how many items are imported using the first rows columns times the amount of row
    this.totalItems = (Object.keys(items.data[0]).length - 1) * items.data.length;

    this.loading = true;
    for (const vehicle of items.data) {
      const events = Object.keys(vehicle);

      for (const event of events) {
        try {
          // If we don't have a template, skip for now.
          if (!Object.values(RecurringEventEnum).includes(event as RecurringEventEnum)) {
            continue;
          }

          const newEvent = new EventUIDto();

          // Somehow the eventTemplate gets mutated on the `setRuleFromTemplate` (`set ruleOptions`). So we copy it here ¯\_(ツ)_/¯
          const eventTemplate = deepCopy(this.findtemplatelByEnum(event as RecurringEventEnum));
          if (!eventTemplate) {
            continue;
          }

          newEvent.start = new Date(vehicle[event]).getTime();
          newEvent.setRuleFromTemplate(eventTemplate);
          newEvent.summary = this.$t(eventTemplate.title).toString();
          newEvent.description = this.$t(eventTemplate.description).toString();

          this.$log.debug("createEvent", newEvent.createDto);
          this.items.push(newEvent.createDto);

          this.importedItems.push(await this.createEvent(newEvent, vehicle.vehicleId));
        } catch (error) {
          const errorMessage = (error as Error).message;
          this.failedImports.push({ vehicleId: vehicle.vehicleId, error: errorMessage });
        }
      }
    }
    this.loading = false;
    this.success = true;
  }

  /**
   * Create Calender Event
   */
  async createEvent(event: IEventUIDto, vehicleId: string) {
    const createdEvent = await VehicleEventModule.create({
      partnerId: PartnerModule.partner.id,
      vehicleId: vehicleId,
      data: event.createDto
    });
    await this.createActivity(createdEvent, vehicleId);
    return createdEvent;
  }

  /**
   * Create Activity
   */
  async createActivity(event: IEventUIDto, vehicleId: string) {
    if (event && event.id) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: BackendResourceEnum.VEHICLE,
            refId: vehicleId
          },
          target: [{ refType: BackendResourceEnum.EVENT, refId: event.id }],
          actionType: ActionEnum.CREATE,
          comment: event.summary
        };
        await new ActivityLog({ partnerId: PartnerModule.partner.id, ...data }).create();
      } catch (error) {
        handleError(error);
      }
    }
  }
}
