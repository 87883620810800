






































import Card from "@/components/utility/Card.vue";
import { MrfiktivCreateVehicleDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Vue, Prop } from "vue-property-decorator";
import ExcelImport from "../utility/ExcelImport.vue";
import { HttpException } from "@/lib/exceptions/http";
import {
  excelDateToJSDate,
  vehicleTypeValidation,
  transmissionValidation,
  countrycodeValidation
} from "@/lib/excelImportTransformation/transformers";
import { IExcelImportConfig, DuplicateHandlingEnum } from "@/lib/interfaces/excel-import.interface";
import { FeatureModule } from "@/store/modules/feature.store";
import { UpdateVehicleDto } from "@/models/update-vehicle.dto";
import ExcelImportUpdate from "@/components/utility/ExcelImportUpdate.vue";
import { IVehicle } from "@/models/vehicle.entity";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";

@Component({
  components: { Card, ExcelImport, ExcelImportUpdate }
})
export default class FleetVehicleImportDialog extends Vue {
  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  readonly updateDto = UpdateVehicleDto;

  loading = false;
  success = true;
  importedItems = [];
  headersImport = [];

  config: { [key: string]: IExcelImportConfig } = {
    displayName: {
      label: this.$t("components.fleet.FleetVehicleImportDialog.displayName").toString(),
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    identificationnumber: {
      label: this.$t("objects.vehicle.registration.identificationnumber").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      duplicateHandling: {
        method: DuplicateHandlingEnum.SKIP,
        existingValues: this.vins
      },
      transform: value => value.toString()
    },
    numberplate: {
      label: this.$t("objects.vehicle.registration.numberplate").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    commissioningDate: {
      label: this.$t("components.fleet.FleetVehicleImportDialog.commissioningDate").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    note: {
      label: this.$t("components.fleet.FleetVehicleImportDialog.note").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationFirstname: {
      label: this.$t("objects.vehicle.registration.firstname").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationName: {
      label: this.$t("objects.vehicle.registration.name").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationStreet: {
      label: this.$t("objects.vehicle.registration.street").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationZipCode: {
      label: this.$t("objects.vehicle.registration.zipCode").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationCity: {
      label: this.$t("objects.vehicle.registration.city").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationCountry: {
      label: this.$t("objects.vehicle.registration.country").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => countrycodeValidation(value)
    },
    registrationHuMonth: {
      label: this.$t("objects.vehicle.registration.huMonth").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationHuYear: {
      label: this.$t("objects.vehicle.registration.huYear").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationManufacturerNameCode: {
      label: this.$t("objects.vehicle.registration.manufacturerNameCode").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationManufacturerTypeCode: {
      label: this.$t("objects.vehicle.registration.manufacturerTypeCode").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationDriveTyp: {
      label: this.$t("objects.vehicle.registration.driveTyp").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationTransmission: {
      label: this.$t("objects.vehicle.registration.transmission").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => transmissionValidation(value)
    },

    registrationFirstregistrationDay: {
      label: this.$t("objects.vehicle.registration.firstregistrationDay").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationFirstregistrationMonth: {
      label: this.$t("objects.vehicle.registration.firstregistrationMonth").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationFirstregistrationYear: {
      label: this.$t("objects.vehicle.registration.firstregistrationYear").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationManufacturerName: {
      label: this.$t("objects.vehicle.registration.manufacturerName").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationManufacturerType: {
      label: this.$t("objects.vehicle.registration.manufacturerType").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationManufacturerDescription: {
      label: this.$t("objects.vehicle.registration.manufacturerDescription").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    registrationVehicleClass: {
      label: this.$t("objects.vehicle.registration.vehicleClass").toString(),
      hint: "",
      required: false,
      import: false,
      originColumnNameInExcelFile: "",
      transform: value => vehicleTypeValidation(value)
    }
  };

  items: MrfiktivCreateVehicleDtoGen[] = [];

  get isCsvExport() {
    return FeatureModule.isCsvExport;
  }

  changeItems(items: { data: any[]; headers: any[] }) {
    this.items = [];
    for (const row of items.data) {
      const createVehicle: MrfiktivCreateVehicleDtoGen = {
        identificationnumber: row.identificationnumber?.replace(/\n|\r/g, ""),
        displayName: row.displayName?.replace(/\n|\r/g, ""),
        commissioningDate: row.commissioningDate,
        note: row?.note || "",
        numberplate: row.numberplate?.replace(/\n|\r/g, ""),
        registration: {
          firstname: row?.registrationFirstname?.replace(/\n|\r/g, "") || "",
          name: row?.registrationName?.replace(/\n|\r/g, "") || "",
          street: row?.registrationStreet?.replace(/\n|\r/g, "") || "",
          zipCode: row?.registrationZipCode?.replace(/\n|\r/g, "") || "",
          city: row?.registrationCity?.replace(/\n|\r/g, "") || "",
          huYear: row?.registrationHuYear?.replace(/\n|\r/g, "") || "",
          huMonth: row?.registrationHuMonth?.replace(/\n|\r/g, "") || "",
          manufacturerNameCode: row?.registrationManufacturerNameCode?.replace(/\n|\r/g, "") || "",
          manufacturerTypeCode: row?.registrationManufacturerTypeCode?.replace(/\n|\r/g, "") || "",
          driveTyp: row?.registrationDriveTyp?.replace(/\n|\r/g, "") || "",
          identificationnumber: row?.identificationnumber?.replace(/\n|\r/g, "") || "",
          transmission: row?.registrationTransmission?.replace(/\n|\r/g, "") || "",
          numberplate: row?.numberplate?.replace(/\n|\r/g, "") || "",
          country: row?.registrationCountry?.replace(/\n|\r/g, "") || "",
          firstregistrationDay: row?.registrationFirstregistrationDay?.replace(/\n|\r/g, "") || "",
          firstregistrationMonth: row?.registrationFirstregistrationMonth?.replace(/\n|\r/g, "") || "",
          firstregistrationYear: row?.registrationFirstregistrationYear?.replace(/\n|\r/g, "") || "",
          manufacturerName: row?.registrationManufacturerName?.replace(/\n|\r/g, "") || "",
          manufacturerType: row?.registrationManufacturerType?.replace(/\n|\r/g, "") || "",
          manufacturerDescription: row?.registrationManufacturerDescription?.replace(/\n|\r/g, "") || "",
          vehicleClass: row?.registrationVehicleClass?.replace(/\n|\r/g, "")
        }
      };

      this.$log.debug("createVehicle", createVehicle);
      this.items.push(createVehicle);
    }
  }

  get vins(): string[] {
    return VehicleModule.paginationList
      .filter(vehicle => vehicle.identificationnumber !== undefined)
      .map(vehicle => vehicle.identificationnumber as string);
  }

  async create(items: { data: any[]; headers: any[] }) {
    this.loading = true;
    this.success = false;
    try {
      this.changeItems(items);
      this.$log.debug("imported", items.data);
      this.$log.debug("createVehicle", this.items);
      await VehicleModule.createBatch({ partnerId: PartnerModule.partner._id, data: this.items });
      this.success = true;
      this.refresh();
    } catch (error) {
      this.$log.error(error);

      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
      this.success = false;
    }

    this.loading = false;
  }

  refresh() {
    this.$emit("refresh", true);
  }

  onUpdated(result: IVehicle) {
    VehicleAccessLayer.set(result);
  }
}
