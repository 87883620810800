


























































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { DocumentTypeEnum } from "@/lib/enum/documentType.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { VehicleActivityLogModule } from "@/store/modules/activity-log.store";
import DocumentSelectionDialog from "@/views/sign/DocumentSelectionDialog.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { SignDocument } from "@/models/sign-document.entity";
import { ActivityLog, IActivityLog } from "@/models/activity-log.entity";
import { IActivity } from "@/models/caseEntity";

@Component({
  components: { ConfirmActionDialog, DocumentSelectionDialog, TableWrapper, Card, ComboBox }
})
export default class FleetVehicleDetailDocumentCard extends Vue {
  @Prop()
  vehicle!: IVehicle;

  get ActivityTypeEnum() {
    return ActivityTypeEnum;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.selectedDocument,
        text: String(this.$t("components.fleet.FleetVehicleDetailContractTable.openNew"))
      },
      {
        icon: "mdi-trash-can",
        action: this.removeDocument,
        disabled: this.loadingDeletion,
        text: String(this.$t("components.fleet.FleetVehicleDetailContractTable.delete"))
      }
    ];
  }

  content: File | undefined;
  type: DocumentTypeEnum | undefined;
  tags: string[] = [];
  loadingDeletion = false;

  selected: IActivity[] = [];

  isAddDocumentDialog = false;

  isEditAddDocumentDialog = false;

  get reference() {
    return {
      refType: BackendResourceEnum.VEHICLE,
      refId: this.vehicle.id
    };
  }

  get partnerId() {
    return this.vehicle.partnerId;
  }

  get rules() {
    return [requiredRule()];
  }

  get documentTypes() {
    return Object.values(DocumentTypeEnum);
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailDocumentCard") || {};
  }

  readonly headers: ITableWrapperHeader[] = [
    { text: this.i18n["createdDate"], value: "timestamp.created", type: "date", align: "center", width: "150px" },
    { text: this.i18n["creator"], value: "name", width: "150px" },
    { text: this.i18n["description"], value: "comment" },
    { text: "", value: "controls", align: "end", sortable: false, width: "110px" }
  ];

  get allDocuments() {
    const activities = VehicleActivityLogModule.filteredAndSorted;
    return activities.filter(a => a.activity === ActivityTypeEnum.CREATE_DOCUMENT);
  }

  addDocument() {
    this.isAddDocumentDialog = true;
  }

  confirmAddDocumentDialog() {
    this.isAddDocumentDialog = false;
    this.isEditAddDocumentDialog = true;
  }

  confirmNewDocument() {
    this.isEditAddDocumentDialog = false;
  }

  selectedDocument(docLog: IActivityLog) {
    const target = docLog.target?.find(t => t.refType === BackendResourceEnum.DOCUMENT);
    if (!target) {
      return;
    }

    new GoToHelper(this.$router).goToDocumentDetail(target.refId, this.partnerId, true);
  }

  async removeDocument(docLog: IActivityLog) {
    this.loadingDeletion = true;

    const deleteActivity = new ActivityLog({
      partnerId: docLog.partnerId,
      id: docLog.id
    })
      .delete()
      .catch(handleError);

    const deleteDocument = await new SignDocument({ partnerId: docLog.partnerId, id: docLog.id }).delete();

    await Promise.all([deleteActivity, deleteDocument]);

    this.loadingDeletion = false;
  }
}
