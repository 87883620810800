









































































































































































































































































import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import UserNameInfo from "@/components/utility/UserNameInfo.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { emailRuleOptional } from "@/lib/rules/contactRule";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { Company, ICompany } from "@/models/company.entity";
import { IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { ISignDocument } from "@/models/sign-document.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { handoverStateMap } from "@/store/mapper/handover-state.map";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import DocumentSelectionDialog from "@/views/sign/DocumentSelectionDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import AssigneeUpdateMixin from "../project/AssigneeUpdateMixin.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import Debug from "../utility/Debug.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb, IChips } from "../utility/mmmint/MHeader.vue";
import MImageMarker from "../utility/mmmint/MImageMarker.vue";
import MNavigationList from "../utility/mmmint/MNavigationList.vue";
import MQuickLinkList from "../utility/mmmint/MQuickLinkList.vue";
import RefsList from "../utility/RefsList.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import CompanyForm from "./CompanyForm.vue";
import CreateAgreementDialog from "./CreateAgreementDialog.vue";
import CreateHandoverFindingDialog from "./CreateHandoverFindingDialog.vue";
import CreateHandoverSnapshotDialog from "./CreateHandoverSnapshotDialog.vue";
import HandoverDetailHandoverDateListItem from "./HandoverDetailHandoverDateListItem.vue";
import InspectionCard from "./InspectionCard.vue";
import InspectionCreateDialog from "./InspectionCreateDialog.vue";
import TourHandoverFindingDialog from "./TourHandoverFindingDialog.vue";

@Component({
  components: {
    Debug,
    MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MImageMarker,
    MNavigationList,
    MQuickLinkList,
    CreateHandoverFindingDialog,
    CreateHandoverSnapshotDialog,
    TourHandoverFindingDialog,
    InspectionCard,
    RefsList,
    RefsVehicle,
    PaginatedTable,
    ActivityCard,
    LatestEntriesCardEmpty,
    InspectionCreateDialog,
    TemplateEditor,
    ConfirmActionDialog,
    TimelineCard,
    ContextMenu,
    CompanyForm,
    UserNameInfo,
    CreateAgreementDialog,
    HandoverDetailHandoverDateListItem,
    DocumentSelectionDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class HandoverDetail extends mixins(PermissionMixin, AssigneeUpdateMixin) {
  @Prop()
  value!: IHandover;

  @Prop({ default: [] })
  vehicles!: IVehicle[];

  @Prop({ default: false })
  isLoading!: boolean;

  readonly handoverReportStore = HandoverReportModule;

  isEditDescriptionMenu = false;
  isLoadingSaveDescription = false;
  descriptionCopy = "";

  companyCopy: ICompany = new Company();

  isCompanyFormValid = false;

  isCompanyFormLoading = false;

  isCompanyAddDialog = false;

  isCompanyUpdateDialog = false;

  isCompanyRemoveDialog = false;

  editCompanyIndex = 0;

  removeCompanyIndex = 0;

  isAddingAttachmentLoading = false;

  isDocumentDialogActive = false;

  get emailRuleOptional() {
    return [emailRuleOptional()];
  }

  get partnerId() {
    return this.value.partnerId;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get title() {
    if (!this.value) {
      return this.$t("common.handover");
    }

    return `#${this.value.number} ${this.value.title}`;
  }

  get subtitle() {
    return "";
  }

  get isTrain() {
    return PartnerModule.isTrain;
  }

  get chips(): IChips[] {
    const chips: IChips[] = [];

    if (this.value.date) {
      chips.push({ text: simpleDate(this.value.date), key: "date" });
    }

    const state = handoverStateMap.get(this.value.state);

    if (!state) {
      return chips;
    }

    chips.push({ text: this.$t(state.label).toString(), key: "state", color: state.color });

    return chips;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.value) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.value.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.value.title, 30);
    } else {
      handoverTitle = `#${this.value.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: true,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.value.partnerId,
          handoverId: this.value.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push(
      {
        text: this.$t("finding.create.title").toString(),
        key: "createDamage",
        icon: "mdi-wrench-outline",
        exec: () => (this.$refs.createHandoverFindingDialog as CreateHandoverFindingDialog)?.open()
      },
      {
        text: this.$t("objects.handover.tour.start").toString(),
        key: "startTour",
        icon: "mdi-ray-start-arrow",
        exec: () => (this.$refs.tourDialog as TourHandoverFindingDialog)?.open()
      },
      {
        text: this.$t("inspection.create.title").toString(),
        key: "newInspection",
        icon: "mdi-calendar-clock",
        exec: this.openCreateInspectionDialog
      },
      {
        text: this.$t("snapshot.create").toString(),
        key: "showDetails",
        icon: "mdi-note-edit-outline",
        exec: () => (this.$refs.createSnapshotDialog as CreateHandoverSnapshotDialog)?.open()
      },
      {
        text: $t("snapshot.createAgreement"),
        key: "newAgreeemnt",
        icon: "mdi-comment-text-multiple-outline",
        exec: () => (this.$refs.createAgreementDialog as CreateAgreementDialog)?.open()
      },
      {
        text: $t("handover.attachments.create"),
        key: "newAttachment",
        icon: "mdi-attachment",
        exec: () => (this.isDocumentDialogActive = true)
      }
    );

    if (this.value.state === HandoverStateEnum.OPEN) {
      actions.push({
        text: this.$t("objects.handover.close").toString(),
        key: "delete",
        icon: "mdi-close-circle-outline",
        color: "dark-purple",
        exec: () => this.closeHandover()
      });
    }

    if (this.value.state === HandoverStateEnum.CLOSED) {
      actions.push({
        text: this.$t("objects.handover.open").toString(),
        key: "open",
        icon: "mdi-check-circle-outline",
        exec: () => this.openHandover()
      });
    }

    return actions;
  }

  get tableShowMoreAction() {
    // return {
    //   text: $t("designGuide.MDetailForm.allProps"),
    //   key: "showDetails"
    // };

    return undefined;
  }

  get navigation(): IAction[] {
    return [
      {
        text: this.$t("handover.report.title").toString(),
        key: "createDamage",
        icon: "mdi-magnify",
        color: "success",
        count: this.handoverReportStore._pageProvider.totalItems,
        exec: this.goToHandoverReports
      },
      {
        text: this.$t("common.nouns.inspections").toString(),
        key: "createInspection",
        icon: "mdi-note-edit-outline",
        color: "warning",
        count: this.value.inspections?.length,
        exec: this.goToInspections
      },
      {
        text: this.$t("snapshot.title").toString(),
        key: "showDetails",
        icon: "mdi-code-tags",
        color: "info",
        count: this.snapshots.length,
        exec: this.goToHandoverSnapshots
      },
      {
        text: this.$t("partner.ActivityLogTable.title").toString(),
        key: "showActivities",
        icon: "mdi-timeline-check-outline",
        color: "black",
        count: this.activityCount,
        exec: this.goToActivities
      },
      {
        text: $t("handover.agreement.agreements"),
        key: "showAgreements",
        icon: "mdi-comment-text-multiple-outline",
        color: "yellow darken-2",
        exec: this.goToAgreements,
        count: this.value.agreements?.length,
        disabled: false
      },
      {
        text: $t("common.nouns.attachments"),
        key: "showAttachments",
        icon: "mdi-attachment",
        color: "pink darken-2",
        exec: this.goToAttachments,
        count: this.value.attachmentIds?.length,
        disabled: false
      },
      {
        text: this.$t("navCategory.analytics").toString(),
        key: "showAnalysis",
        icon: "mdi-chart-line",
        color: "grey",
        count: this.value.agreements?.length,
        disabled: true
      }
    ];
  }

  get quickLinks() {
    return [
      {
        text: "Neuer Befund",
        description: "Dokumentieren Sie Schäden und Befunde am Fahrzeug",
        key: "newFinding",
        color: "success",
        exec: () => (this.$refs.createHandoverFindingDialog as CreateHandoverFindingDialog)?.open(),
        disabled: !this.value.inspections.length
      },
      {
        text: this.$t("objects.handover.tour.start").toString(),
        description: this.$t("objects.handover.tour.description").toString(),
        key: "startTour",
        color: "success",
        exec: () => (this.$refs.tourDialog as TourHandoverFindingDialog)?.open()
      },
      {
        text: "Begehung erstellen",
        description: "Legen Sie eine Begehung mit Ort, Datum, Teilnehmenden und mehr an.",
        key: "newInspection",
        color: "warning",
        exec: this.openCreateInspectionDialog
      },
      {
        text: this.$t("snapshot.create").toString(),
        description: this.$t("snapshot.description").toString(),
        key: "newProtocol",
        color: "info",
        exec: () => (this.$refs.createSnapshotDialog as CreateHandoverSnapshotDialog)?.open(),
        multiple: [
          {
            text: this.$t("snapshot.preview").toString(),
            key: "previewProtocol",
            exec: () => (this.$refs.createSnapshotDialog as CreateHandoverSnapshotDialog)?.open(),
            description: this.$t("snapshot.previewDescription").toString(),
            icon: "mdi-magnify"
          },
          {
            text: this.$t("snapshot.create").toString(),
            key: "createProtocol",
            exec: () => (this.$refs.createSnapshotDialog as CreateHandoverSnapshotDialog)?.create(),
            description: this.$t("snapshot.createDescription").toString(),
            icon: "mdi-archive-lock-outline"
          }
        ]
      },
      {
        text: $t("snapshot.createAgreement"),
        description: $t("snapshot.createAgreementDetail"),
        key: "newAgreeemnt",
        color: "yellow darken-2",
        exec: () => (this.$refs.createAgreementDialog as CreateAgreementDialog)?.open()
      },
      {
        text: $t("handover.attachments.create"),
        description: $t("handover.attachments.createDetail"),
        key: "createAttachments",
        color: "pink darken-2",
        exec: () => (this.isDocumentDialogActive = true)
      }
    ];
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "state",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "open", displayValue: "Offen", color: "success" },
          { value: "closed", displayValue: "Kunde", color: "grey" }
        ]
      },
      {
        key: "description",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "owner",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "keeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "newKeeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "timestamp.created",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      }
    ];
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.value.id
    };
  }

  get activities() {
    return ActivityLogModule.filteredAndSorted;
  }

  get activityCount() {
    return ActivityLogModule.totalItems;
  }

  get users() {
    return PartnerUserModule.paginationList;
  }

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100
      },
      {
        text: "Title",
        value: "title",
        width: 90
      },
      {
        text: "Message",
        value: "message",
        width: 90
      }
    ];
  }

  get partner() {
    return PartnerModule.partner;
  }

  get isMail() {
    return !!this.value.attendees;
  }

  get mails() {
    return this.value.attendees.map(u => u.email).join("; ");
  }

  get snapshots() {
    return HandoverSnapshotModule.entities;
  }

  get assignees() {
    return this.value.assignees as string[];
  }

  set assignees(value: string[]) {
    const oldAssignees = this.assignees;
    const newAssignees = value;

    this.debounceChangeAssignees(newAssignees, oldAssignees, this.value.id);

    this.value.assignees = value;
  }

  async beforeMount() {
    HandoverSnapshotModule.setFilters([
      new PageFilterElement({
        key: "handoverId",
        value: this.$route.params.handoverId.toString(),
        operation: PageFilterOperationEnum.EQUAL
      })
    ]);
  }

  openCreateInspectionDialog() {
    (this.$refs.createHandoverFindingDialog as CreateHandoverFindingDialog)?.close();
    (this.$refs.inspectionCreateDialog as InspectionCreateDialog)?.open();
  }

  async onAttachmentCreated(attachment: ISignDocument) {
    const attachmentIds = [...this.value.attachmentIds];
    attachmentIds.push(attachment.id);

    this.isAddingAttachmentLoading = true;
    try {
      attachment.refs.push({ refType: this.ResourceEnum.HANDOVER, refId: this.value.id });
      await attachment.update();

      await this.value.updatePartial({ attachmentIds }).catch(handleError);
      this.isDocumentDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isAddingAttachmentLoading = false;
    }
  }

  goToHandoverReports() {
    new GoToHelper(this.$router).goToHandoverReportTableView(this.value.id, this.value.partnerId, undefined, false);
  }

  goToHandoverSnapshots() {
    new GoToHelper(this.$router).goToSnapshotTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspections() {
    new GoToHelper(this.$router).goToInspectionTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspection(id: string) {
    new GoToHelper(this.$router).goToInspectionDetail(id, this.value.id, this.value.partnerId, false);
  }

  goToVehicle(value: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(value.id, value.partnerId, VehicleTabs.HOME, true);
  }

  goToActivities() {
    this.$router.push({
      name: "ActivityTableView",
      params: {
        partnerId: this.value.partnerId,
        handoverId: this.value.id
      }
    });
  }

  goToAgreements() {
    this.$router.push({
      name: "HandoverAgreementsTableView",
      params: {
        partnerId: this.value.partnerId,
        handoverId: this.value.id
      }
    });
  }

  goToAttachments() {
    this.$router.push({
      name: "HandoverAttachmentsTableView",
      params: {
        partnerId: this.value.partnerId,
        handoverId: this.value.id
      }
    });
  }

  async setHandoverState(state: HandoverStateEnum) {
    try {
      this.isLoading = true;
      await this.value.updatePartial({ state });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async closeHandover() {
    await this.setHandoverState(HandoverStateEnum.CLOSED);
  }

  async openHandover() {
    await this.setHandoverState(HandoverStateEnum.OPEN);
  }

  openAddCompanyDialog() {
    this.companyCopy = new Company();
    this.isCompanyAddDialog = true;
  }

  openUpdateCompanyDialog(i: number) {
    this.editCompanyIndex = i;
    this.companyCopy = new Company(this.value.parties[i]);
    this.isCompanyUpdateDialog = true;
  }

  openRemoveCompanyDialog(i: number) {
    this.removeCompanyIndex = i;
    this.isCompanyRemoveDialog = true;
  }

  async saveCompanyRemoveDialog() {
    try {
      this.isLoading = true;

      this.value.parties.splice(this.removeCompanyIndex, 1);
      await this.value.update();

      this.removeCompanyIndex = 0;
      this.isCompanyRemoveDialog = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  abortCompanyRemoveDialog() {
    this.removeCompanyIndex = 0;
    this.isCompanyRemoveDialog = false;
  }

  abortCompanyAddDialog() {
    this.companyCopy = new Company();
    this.isCompanyAddDialog = false;
  }

  abortCompanyUpdateDialog() {
    this.companyCopy = new Company();
    this.editCompanyIndex = 0;
    this.isCompanyUpdateDialog = false;
  }

  async saveCompanyAddDialog() {
    if (!this.companyCopy) {
      return;
    }

    this.isCompanyFormLoading = true;

    try {
      this.value.parties.push(this.companyCopy);
      await this.value.update();

      this.companyCopy = new Company();
      this.isCompanyAddDialog = false;
    } catch (error) {
      // if update fails, then don't add the company to the list
      this.value.parties.pop();
      handleError(error);
    } finally {
      this.isCompanyFormLoading = false;
    }
  }

  async saveCompanyUpdateDialog() {
    if (!this.companyCopy) {
      return;
    }

    this.isCompanyFormLoading = true;

    try {
      this.value.parties.splice(this.editCompanyIndex, 1, this.companyCopy);
      await this.value.update();

      this.companyCopy = new Company();
      this.isCompanyUpdateDialog = false;
      this.editCompanyIndex = 0;
    } catch (error) {
      handleError(error);
    } finally {
      this.isCompanyFormLoading = false;
    }
  }

  openEditDescriptionMenu() {
    this.descriptionCopy = this.value.description;
    this.isEditDescriptionMenu = true;
  }

  abortEditDescription() {
    this.isEditDescriptionMenu = false;
    this.descriptionCopy = this.value.description;
  }

  async saveEditDescription() {
    this.isLoadingSaveDescription = true;

    try {
      await this.value.updatePartial({
        description: this.descriptionCopy
      });

      this.isEditDescriptionMenu = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingSaveDescription = false;
    }
  }

  /**
   * @override
   * @param added
   * @param removed
   */
  async changeAssignees(added: string[], removed: string[], all: string[], documentId: string) {
    try {
      this.isLoading = true;

      const source = {
        refType: BackendResourceEnum.HANDOVER,
        refId: documentId
      };

      const partnerId = this.partner.id;

      await this.value.updatePartial({ assignees: all });

      const promises = [];
      if (removed.length > 0) {
        const promise = new ActivityLog().createAssigneeActivity({
          partnerId,
          source,
          newAssignees: removed,
          activityType: ActivityTypeEnum.DELETE_ASSIGNEE
        });

        promises.push(promise);
      }
      if (added.length > 0) {
        const promise = new ActivityLog().createAssigneeActivity({
          partnerId,
          source,
          newAssignees: added,
          activityType: ActivityTypeEnum.CREATE_ASSIGNEE
        });

        promises.push(promise);
      }

      await Promise.all(promises);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
