



























































































































































































































import Debug from "@/components/utility/Debug.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { ActivityLog } from "@/models/activity-log.entity";
import { ITicket } from "@/models/ticket.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { RRule } from "rrule";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import EventCardAcknowledgementDialog from "../event/EventCardAcknowledgementDialog.vue";
import TicketCreateDialog from "../project/TicketCreateDialog.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IAlert, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import RefsSelected from "../utility/RefsSelected.vue";
import RefsUser from "../utility/RefsUser.vue";
import EventCardRecurringList from "./EventCardRecurringList.vue";
import EventRecurring from "./EventRecurring.vue";

enum EventActionEnum {
  ACKNOWLEDGE = "acknowledge",
  DELETE_EVENT = "deleteEvent",
  OPEN_IN_NEW = "openInNew",
  EDIT = "edit",
  VIEW_RECURRING = "viewRecurring",
  CREATE_TASK = "createTask"
}

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    MActionList,
    MDetailTable,
    ActivityCard,
    RefsSelected,
    RefsUser,
    ConfirmActionDialog,
    Debug,
    TicketCreateDialog,
    EventRecurring,
    EventCardAcknowledgementDialog,
    EventCardRecurringList
  },
  filters: { simpleDoubleDigitDate }
})
export default class EventCard extends Vue {
  @Prop()
  value!: IEventUIDto;

  @Prop()
  loading!: boolean;

  @Prop({ default: false })
  hideInNewTabButton!: boolean;

  @Prop({ default: false })
  hideBreadcrumbs!: boolean;

  @Prop()
  vehicle?: IVehicle;

  /**
   * Exchange actions with an edit title button. Useful for event side card
   */
  @Prop()
  showTitleEdit?: boolean;

  @Ref("eventCardAcknowledgementDialog")
  eventCardAcknowledgementDialog!: EventCardAcknowledgementDialog;

  readonly START = "start";
  readonly END = "end";
  readonly DETAIL = "detail";
  readonly ACKNOWLEDGE = "acknowledge";
  readonly OVERDUE = "overdue";
  readonly EDIT = "edit";
  readonly VIEW_RECURRING = "viewRecurring";
  readonly DELETE = "delete";
  readonly TASK = "task";

  isUnacknowledgeConfirmation = false;

  isUnacknowledgeMenu = false;

  isUnacknowledgementLoading = false;

  isEditingTitle = false;

  editTitleCopy = this.value.summary;

  startEditingTitle() {
    this.editTitleCopy = this.value.summary;
    this.isEditingTitle = true;
  }

  cancelEditTitle() {
    this.isEditingTitle = false;
    this.editTitleCopy = this.value.summary;
  }

  saveEditTitle() {
    this.isEditingTitle = false;
    this.value.summary = this.editTitleCopy;
    this.emitUpdate();
  }

  isEditDescription = false;

  changesDetected = false;

  descriptionCopy = this.value.description;

  isDeleteDialogActive = false;

  isTicketCreateDialogActive = false;

  startStringCopy = this.value.startString;

  showConfirmDatePicker = false;

  get vehicleRef() {
    return {
      refId: this.value.vehicleId,
      refType: BackendResourceEnum.VEHICLE
    };
  }

  get eventRef() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.EVENT
    };
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.EVENT
    };
  }

  get recurrenceDescription() {
    if (this.value.rrule) {
      return RRule.fromString(this.value.rrule).toText();
    }

    return "";
  }

  get isOverdue() {
    return this.value.isOverdue;
  }

  get baseCrumbs(): IBreadcrumb[] {
    if (this.hideBreadcrumbs) {
      return [];
    }

    let vehicle = this.vehicle;
    if (!vehicle) vehicle = VehicleModule.maps.id.get(this.value.vehicleId ?? "")[0];
    const breadCrumbs: IBreadcrumb[] = [];

    //FLEET
    breadCrumbs.push({
      text: $t("common.nouns.fleet"),
      disabled: false,
      exact: false,
      to: {
        name: "FleetHomeTab",
        params: {
          partnerId: this.partner.id,
          tab: VehicleTabs.EVENT
        }
      }
    });

    //VEHICLE
    breadCrumbs.push({
      text: vehicle?.displayName ?? vehicle?.numberplate ?? this.value.vehicleId ?? $t("common.nouns.vehicle"),
      disabled: false,
      exact: false,
      to: {
        name: "VehicleDetailTab",
        params: {
          partnerId: this.partner.id,
          vehicleId: this.value.vehicleId as string,
          tab: VehicleTabs.EVENT
        }
      }
    });

    return breadCrumbs;
  }

  get breadCrumbs(): IBreadcrumb[] | undefined {
    if (this.hideBreadcrumbs) {
      return undefined;
    }
    const breadCrumbs: IBreadcrumb[] = this.baseCrumbs;

    //EVENT
    breadCrumbs.push({
      text: this.value.isRecurringRoot ? $t("common.nouns.series") : $t("common.nouns.appointment"),
      disabled: true,
      exact: true,
      to: {
        name: "PartnerEvents",
        params: {
          partnerId: this.partner.id,
          vehicleId: this.value.vehicleId as string,
          eventId: this.value.id as string
        }
      }
    });

    return breadCrumbs;
  }

  get chips() {
    const breadyumm = [];
    if (!this.value.isRecurringRoot && !this.value.ack && this.isOverdue) {
      breadyumm.push({
        text: $t("overdue"),
        color: "red"
      });
    }

    if (this.value.isRecurringRoot) {
      breadyumm.push({
        text: $t("components.fleet.FleetHomeCalendar.recurring"),
        color: "info",
        icon: "mdi-sync"
      });
    }

    return breadyumm;
  }

  get creator() {
    const user = this.getUserFromId(this.value.userId);

    return user;
  }

  get subtitle() {
    if (this.creator) {
      return this.$t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: this.creator?.firstName ?? "" + " " + this.creator?.lastName ?? ""
      });
    }

    return this.$t("createdOn", {
      date: simpleDoubleDigitDate(this.value.timestamp.created)
    });
  }

  get alerts(): IAlert[] {
    const breadyumm: IAlert[] = [];

    if (!this.value.isRecurringRoot && !this.value.ack && this.isOverdue) {
      breadyumm.push({
        text: $t("overdue"),
        type: "error",
        action: this.actionRecord.acknowledge
      });
    }

    return breadyumm;
  }

  readonly actionRecord: Record<EventActionEnum, IAction> = {
    [EventActionEnum.ACKNOWLEDGE]: {
      text: $t("views.fleet.EventCard.markAsAcknowledged"),
      key: this.ACKNOWLEDGE,
      icon: "mdi-check"
    },
    [EventActionEnum.VIEW_RECURRING]: {
      text: $t("components.fleet.FleetHomeCalendar.viewRecurring"),
      key: this.VIEW_RECURRING,
      icon: "mdi-sync"
    },
    [EventActionEnum.DELETE_EVENT]: {
      text: $t("common.verbs.delete"),
      key: this.DELETE,
      icon: "mdi-trash-can",
      color: "red"
    },
    [EventActionEnum.OPEN_IN_NEW]: {
      text: $t("project.ticket.actions.openInNewTab"),
      key: this.DETAIL,
      icon: "mdi-open-in-new"
    },
    [EventActionEnum.EDIT]: {
      text: $t("common.verbs.edit"),
      key: this.EDIT,
      icon: "mdi-pencil"
    },
    [EventActionEnum.CREATE_TASK]: {
      text: $t("project.ticket.createTicket"),
      key: this.TASK,
      icon: "mdi-ticket-outline"
    }
  };

  get actions() {
    const actions: IAction[] = [];

    if (!this.hideInNewTabButton) {
      actions.push(this.actionRecord.openInNew);
    }
    if (!this.value.isRecurringRoot && !this.value.ack) {
      actions.push(this.actionRecord.acknowledge);
    }
    if (
      !this.value.isRecurringRoot &&
      UserModule.abilities.can(ActionEnum.CREATE, BackendResourceEnum.TICKET, this.partner.id)
    ) {
      actions.push(this.actionRecord.createTask);
    }
    if (this.value.recurringEventId) {
      actions.push(this.actionRecord.viewRecurring);
    }
    if (UserModule.abilities.can(ActionEnum.UPDATE, BackendResourceEnum.EVENT, this.partner.id)) {
      actions.push(this.actionRecord.edit);
    }
    if (UserModule.abilities.can(ActionEnum.DELETE, BackendResourceEnum.EVENT, this.partner.id)) {
      actions.push(this.actionRecord.deleteEvent);
    }

    return actions;
  }

  /**
   * Returns the detail table configuration for the contract
   * add properties that are defined, not part of header and simple data types
   */
  get detailTableConfig(): IMDetailTableConfig[] {
    const fields: IMDetailTableConfig[] = [];

    fields.push({
      key: "start",
      type: DetailTableTypeEnum.PROPERTY,
      visualization: DetailTableVisualizationEnum.SLOT
    });

    if (this.value.end) {
      fields.push({
        key: "end",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    fields.push({
      key: "timezone",
      type: DetailTableTypeEnum.PROPERTY,
      visualization: DetailTableVisualizationEnum.CHIP
    });

    if (this.value.ruleOptions) {
      fields.push({
        key: "ruleOptions",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    if (this.value.location) {
      fields.push({
        key: "location",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP
      });
    }

    if (this.value.ack) {
      fields.push({
        key: "ack",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    if (this.getUserFromId(this.value.userId)) {
      fields.push({
        key: "userId",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    } else {
      fields.push({
        key: "timestamp.created",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    return fields;
  }

  initializeDatePicker() {
    this.startStringCopy = this.value.startString;
  }

  startConfirmStartDialog() {
    this.showConfirmDatePicker = true;
  }

  confirmStartDialog() {
    this.value.startString = this.startStringCopy;
    this.showConfirmDatePicker = false;
    this.emitUpdate();
  }

  discardStartDialog() {
    this.startStringCopy = this.value.startString;
    this.showConfirmDatePicker = false;
  }

  formatHoursAndMinutes(date: Date) {
    return formatHoursAndMinutes(date);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  getUserFromId(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  editDescription() {
    this.descriptionCopy = this.value.description;
    this.isEditDescription = true;
  }

  updateDescription() {
    this.value.description = this.descriptionCopy;
    this.isEditDescription = false;
    this.emitUpdate();
  }

  abortUpdateDescription() {
    this.descriptionCopy = this.value.description;
    this.isEditDescription = false;
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.DETAIL:
        this.$emit("goToDetail", this.value);
        break;

      case this.EDIT: {
        this.$emit("goToEdit", this.value);
        break;
      }

      case this.ACKNOWLEDGE: {
        this.eventCardAcknowledgementDialog.open();
        break;
      }

      case this.VIEW_RECURRING: {
        this.goToRecurringEvent();
        break;
      }

      case this.DELETE: {
        this.isDeleteDialogActive = true;
        break;
      }

      case this.TASK: {
        this.isTicketCreateDialogActive = true;
        break;
      }
    }
  }

  goToRecurringEvent() {
    if (!this.value.recurringEventId) return;
    new GoToHelper(this.$router).goToEventDetail(this.partner.id, this.value.recurringEventId, false);
  }

  confirmDeletion() {
    this.isDeleteDialogActive = false;
    this.emitDelete();
  }

  emitUpdate() {
    this.$emit("input", this.value);
  }

  emitDelete() {
    this.$emit("delete", this.value);
  }

  inializeUnacknowledgement() {
    this.isUnacknowledgeMenu = true;
    this.isUnacknowledgeConfirmation = false;
  }

  cancelUnacknowledgementDialog() {
    this.isUnacknowledgeConfirmation = false;
    this.isUnacknowledgeMenu = false;
  }

  async unacknowledge() {
    try {
      this.isUnacknowledgementLoading = true;
      await this.value.unacknowledge();
    } catch (e) {
      handleError(e);
    } finally {
      this.isUnacknowledgementLoading = false;
      this.isUnacknowledgeMenu = false;
    }
  }

  async onTicketCreated(ticket: ITicket) {
    try {
      if (!this.value.id) return;
      await new ActivityLog({
        partnerId: this.partner.id,
        target: [{ refType: BackendResourceEnum.TICKET, refId: ticket.id }],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_TICKET,
        source: { refId: this.value.id, refType: BackendResourceEnum.EVENT }
      }).create();
    } catch (e) {
      this.$log.error(e);
    }
  }
}
