



































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import LatestEntriesCardList from "@/components/cards/LatestEntriesCardList.vue";
import { IListItem } from "@/lib/interfaces/list-item.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

@Component({ components: { LatestEntriesCardList, LatestEntriesCardEmpty } })
export default class LatestEntriesCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get reportListRoute() {
    return `/partners/${PartnerModule.partner._id}/report`;
  }

  get latestReports(): IListItem[] {
    if (ReportPaginationModule.paginationList.length > 0) {
      return ReportPaginationModule.paginationList
        .filter(r => r.progressStatus === ProgressStatusEnum.NEW)
        .slice(0, 3)
        .map(report => ({
          id: report._id,
          title: `${simpleDate(report?.timestamps?.created)} - ${report.title} ${
            report.customerName ? report.customerName : ""
          }`.trim(),
          subtitle: `${this.excerptMessage(report.message)}`
        }));
    }
    return [];
  }

  excerptMessage(message: string) {
    return message?.substring(0, 75) + "...";
  }

  async toReports() {
    ReportPaginationModule.setFilter([
      new PageFilterElement({
        key: "progressStatus",
        value: ProgressStatusEnum.NEW,
        operation: PageFilterOperationEnum.EQUAL
      })
    ]);

    await this.$router.push({ path: this.reportListRoute });
  }

  async route(id: string) {
    const path = this.reportListRoute + `/${id}`;
    await this.$router.push({ path: path });
  }
}
