










import ThirdPartySystemTable from "@/components/third-party-system/ThirdPartySystemTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import { ThirdPartySystemGoToHelper } from "@/lib/utility/third-party-system.go-to-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IThirdPartySystem, ThirdPartySystem } from "@/models/third-party-system.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";

@Component({
  components: {
    ThirdPartySystemTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ThirdPartySystemTableView extends mixins(PartnerFallbackMixin) {
  @Ref("thirdPartySystemTable")
  thirdPartySystemTable!: ThirdPartySystemTable;

  /**
   * refs that will be set in new third-party-systems and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  selectedItems: IThirdPartySystem[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push({
      exact: true,
      text: $t("thirdPartySystem.thirdPartySystems"),
      to: ThirdPartySystemGoToHelper.locations.thirdPartySystemTable({ partnerId: this.partnerId })
    });

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${ThirdPartySystemModule.filtered.length} ${$t("thirdPartySystem.thirdPartySystems")}`,
      key: "thirdPartySystemTable",
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    ThirdPartySystemModule.setHiddenFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const thirdPartySystemId = this.$route.query.thirdPartySystemId as string;
    if (thirdPartySystemId) {
      const thirdPartySystem = new ThirdPartySystem({ partnerId: this.partnerId, id: thirdPartySystemId });
      this.thirdPartySystemTable?.setSelectedItem(thirdPartySystem);
    }
  }

  openSideCard(thirdPartySystem?: IThirdPartySystem) {
    if (thirdPartySystem) {
      new ThirdPartySystemGoToHelper(this.$router).goToThirdPartySystemTable({
        partnerId: this.partnerId,
        query: { thirdPartySystemId: thirdPartySystem.id }
      });
    } else {
      new ThirdPartySystemGoToHelper(this.$router).goToThirdPartySystemTable({
        partnerId: this.partnerId,
        query: {}
      });
    }
  }
}
