


































































































































































































































































































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IAttendee } from "@/models/attendee";
import { IBlueprint } from "@/models/blueprint.entity";
import { IHandover } from "@/models/handover.entity";
import { IInspection } from "@/models/inspection.entity";
import { IReport } from "@/models/report.entity";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivBlueprintElementViewmodelGen, MrfiktivPositionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { ReportModule } from "@/store/modules/report.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import PartnerReportCardImages from "@/components/partner/PartnerReportCardImages.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import RefsReport from "../utility/RefsReport.vue";
import Tooltip from "../utility/tooltip.vue";
import CreateHandoverFindingDialogReportTable from "./CreateHandoverFindingDialogReportTable.vue";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ReportType } from "@/store/enum/reportType";
import { requiredRule } from "@/lib/rules/requiredRule";

enum StepEnum {
  INSPECTION = "inspection",
  LOCATOR = "locator",
  DETAILS = "details",
  SUCCESS = "success"
}

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    PartnerReportCardImages,
    CreateHandoverFindingDialogReportTable,
    RefsReport,
    Tooltip,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CreateHandoverFindingDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  vehicles!: IVehicle[];

  @Prop()
  handover!: IHandover;

  @Prop()
  inspection?: IInspection;

  @Prop({ default: null })
  blueprint!: IBlueprint | null;

  readonly StepEnum = StepEnum;

  /**
   * To identify option in v-select to use no blueprint
   */
  readonly EMPTY_BLUEPRINT_ID = "";

  selectedInspectionId = "";

  isValid = false;

  selectedVehicle: IVehicle = this.blueprint
    ? this.vehicles.find(v => v.blueprints.find(b => b.id === this.blueprint?.id)) ?? this.vehicles[0]
    : this.vehicles[0];

  private selectedBlueprintPrivate: MrfiktivBlueprintElementViewmodelGen | null = this.blueprint;

  get selectedBlueprint() {
    return this.selectedBlueprintPrivate;
  }

  set selectedBlueprint(value) {
    if (value?.id === this.EMPTY_BLUEPRINT_ID) {
      this.selectedBlueprintPrivate = null;

      return;
    }

    this.selectedBlueprintPrivate = value;
  }

  report = new CreateReportAsPartnerDto();

  step: StepEnum = StepEnum.INSPECTION;

  example = AssetEnum.damageDetail;

  isDialogActive = false;

  created: IReport | null = null;

  createdReports: IReport[] = [];

  isRightLoading = false;

  isImageLoading = false;

  isDialogLoading = false;

  meta = "";

  contactTemp: IAttendee | null = null;

  markers: IMarker[] = [];

  confirmProceedNoImagesDialog = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get type() {
    return ReportImageType.damageDetail;
  }

  get blueprints() {
    const blueprints = [];

    blueprints.push({
      name: $t("handover.report.noBlueprintOption"),
      description: $t("handover.report.noBlueprintOptionDescription"),
      id: this.EMPTY_BLUEPRINT_ID
    } as MrfiktivBlueprintElementViewmodelGen);

    blueprints.push(...(this.selectedVehicle?.blueprints ?? []));

    return blueprints;
  }

  get selectedBlueprintId() {
    if (!this.selectedBlueprint) {
      return null;
    }

    return this.selectedBlueprint.id;
  }

  get bluePrintImageUrl() {
    return this.selectedVehicle?.blueprints.find(b => b.id === this.selectedBlueprintId)?.image?.url;
  }

  get bluePrintLocations() {
    return this.selectedVehicle?.blueprints.find(b => b.id === this.selectedBlueprintId)?.location;
  }

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  get hideLeft() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        return true;

      default:
        return false;
    }
  }

  get rightDisabled() {
    switch (this.step) {
      case StepEnum.INSPECTION:
        return !this.selectedInspectionId;
      case StepEnum.DETAILS:
        return !this.isValid;
      case StepEnum.LOCATOR:
        if (this.selectedBlueprint && !this.markers?.length) return true;
        return false;
      default:
        return false;
    }
  }

  get leftText() {
    switch (this.step) {
      case StepEnum.INSPECTION:
        return $t("close");
      case StepEnum.LOCATOR:
        return $t("close");
      case StepEnum.SUCCESS:
        return $t("close");
      default:
        return "Zurück";
    }
  }

  get rightText() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        if (!this.selectedBlueprint) return $t("components.CreateHandoverFindingsDialog.steps.locator.nextNoBlueprint");
        return $t("components.CreateHandoverFindingsDialog.steps.locator.next");
      default:
        return this.$t(`components.CreateHandoverFindingsDialog.steps.${this.step}.next`);
    }
  }

  get title() {
    return this.$t(`components.CreateHandoverFindingsDialog.steps.${this.step}.title`);
  }

  get reportsWithoutBlueprint() {
    return this.createdReports.filter(r => !r.position?.blueprint?.id);
  }

  removeBlueprint() {
    this.selectedBlueprint = null;
    this.bluePrintLocations?.splice(0);
    this.markers?.splice(0);
  }

  open() {
    if (
      !(
        this.can(this.ActionEnum.CREATE, this.ResourceEnum.HANDOVER) ||
        this.can(this.ActionEnum.CREATE, this.ResourceEnum.REPORT)
      )
    ) {
      throw new Error("Permission denied");
    }

    this.selectedInspectionId =
      this.inspection?.id ?? this.handover.inspections[this.handover.inspections.length - 1]?.id ?? "";
    if (this.blueprint) {
      const vehicleForBlueprint = this.vehicles.find(v => v.blueprints.find(b => b.id === this.blueprint?.id));
      if (vehicleForBlueprint) {
        this.selectedVehicle = vehicleForBlueprint;
        this.selectedBlueprintPrivate = this.blueprint;
      }
    } else if (this.vehicles.length) {
      this.selectedVehicle = this.vehicles[0];
    }

    this.isDialogActive = true;
    this.initialize();
    this.createdReports.splice(0);
    this.step = StepEnum.INSPECTION;
  }

  initialize() {
    const report = this.selectedVehicle
      ? CreateReportAsPartnerDto.createFromVehicle(this.selectedVehicle)
      : new CreateReportAsPartnerDto();
    report.title = "";
    this.report = report;
    this.created = null;
    this.meta = "";
    this.markers.splice(0);
  }

  async rightClick() {
    switch (this.step) {
      case StepEnum.INSPECTION:
        this.step = StepEnum.LOCATOR;
        break;
      case StepEnum.LOCATOR:
        this.step = StepEnum.DETAILS;
        break;
      case StepEnum.DETAILS:
        if (!this.report.images?.overviews.length && !this.confirmProceedNoImagesDialog) {
          this.confirmProceedNoImagesDialog = true;
          return;
        }

        this.confirmProceedNoImagesDialog = false;

        this.isRightLoading = true;
        try {
          const partnerId = this.handover.partnerId;
          const inspectionId = this.selectedInspectionId;
          const handoverId = this.handover.id;

          let position: MrfiktivPositionDtoGen | undefined = undefined;
          if (this.selectedBlueprintId) {
            position = {
              blueprintId: this.selectedBlueprintId,
              coordinates: this.markers.length
                ? {
                    x: this.markers[0].left,
                    y: this.markers[0].top
                  }
                : undefined,
              meta: this.meta
            };
          }

          if (this.contactTemp) {
            this.report.companyName = this.contactTemp?.companyName;
            this.report.firstName = this.contactTemp?.firstName;
            this.report.lastName = this.contactTemp?.lastName;

            if (this.contactTemp?.email) {
              this.report.customerContact = {
                email: this.contactTemp.email,
                phone: this.contactTemp?.phone ?? ""
              };
            }
          }

          if (PartnerModule.partner?.partnerType === PartnerTypeEnum.TRAIN) {
            this.report.reportType = ReportType.TRAIN;
          }

          this.created = await this.report.createAsInspectionFinding(partnerId, handoverId, inspectionId, position);
          this.createdReports.push(this.created);

          ReportModule.reset();
          this.contactTemp = null;
          (this.$refs.images as PartnerReportCardImages).reset();
          this.step = StepEnum.SUCCESS;
        } catch (e) {
          handleError(e);
        } finally {
          this.isRightLoading = false;
        }
        break;
      case StepEnum.SUCCESS:
        this.initialize();
        this.step = StepEnum.LOCATOR;
        break;
    }
  }

  async leftClick() {
    switch (this.step) {
      case StepEnum.INSPECTION:
        this.close();
        break;
      case StepEnum.LOCATOR:
        this.close();
        break;
      case StepEnum.DETAILS:
        this.step = StepEnum.LOCATOR;
        break;
      case StepEnum.SUCCESS:
        this.close();
        break;
    }
  }

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers = [marker];
  }

  setIsLoadingImage(isLoading: boolean) {
    this.isImageLoading = isLoading;
  }

  close() {
    this.$emit("close");
    this.isDialogActive = false;
  }

  reportsForBlueprint(blueprintId: string) {
    const reports = this.createdReports.filter(r => r.position?.blueprint.id === blueprintId);

    return reports;
  }
  color(item: IReport) {
    return reportStateMap.get(item.progressStatus as ProgressStatusEnum)?.color || "blue-grey lighten-4";
  }

  goToDetailFormForMarker({ blueprint, marker }: { blueprint: MrfiktivBlueprintElementViewmodelGen; marker: IMarker }) {
    this.selectedBlueprint = blueprint;
    this.markers = [marker];
    this.report = this.selectedVehicle
      ? CreateReportAsPartnerDto.createFromVehicle(this.selectedVehicle)
      : new CreateReportAsPartnerDto();
    this.report.title = "";
    this.step = StepEnum.DETAILS;
    this.isDialogLoading = false;
  }
}
