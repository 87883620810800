var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-timeline",
        {
          attrs: {
            dense: "",
            clipped: ""
          }
        },
        [
          _vm.report
            ? _c("report-base-time-line", {
                attrs: {
                  report: _vm.report
                }
              })
            : _vm._e(),
          _c("v-timeline-item", {
            attrs: {
              "hide-dot": ""
            }
          }),
          _vm._l(_vm.metas, function(meta) {
            return _c(
              "div",
              {
                key: meta._id,
                attrs: {
                  md: _vm.metaRowAmount
                }
              },
              [
                _c("partner-report-detail-meta-time-line-element", {
                  attrs: {
                    meta: meta
                  }
                })
              ],
              1
            )
          }),
          _c("activity-time-line", {
            attrs: {
              activities: _vm.activities
            }
          }),
          _c("v-timeline-item", {
            attrs: {
              "hide-dot": ""
            }
          })
        ],
        2
      ),
      _c("v-divider", {
        staticClass: "d-print-none"
      }),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            inset: ""
          },
          model: {
            value: _vm.sheet,
            callback: function callback($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet"
          }
        },
        [
          _c(
            "v-sheet",
            {
              attrs: {
                height: "400px"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("h1", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                            )
                          )
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.sheet = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("report-activity-box-comment-component", {
                    attrs: {
                      report: _vm.report
                    },
                    on: {
                      save: function save($event) {
                        _vm.sheet = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-row",
            {
              staticClass: "pt-4"
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _vm._l(_vm.mobileMenu, function(action, i) {
                        return [
                          action.isEnabled
                            ? _c(
                                "v-btn",
                                {
                                  key: i,
                                  staticClass: "mt-2",
                                  attrs: {
                                    value: "left",
                                    outlined: "",
                                    color: action.color,
                                    large: "",
                                    block: ""
                                  },
                                  on: {
                                    click: action.action
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(action.title) + " "),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        right: ""
                                      }
                                    },
                                    [_vm._v(_vm._s(action.icon))]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            {
              staticClass: "pt-4 d-print-none"
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "min-width": "96px"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        right: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref) {
                            var on = _ref.on
                            return [
                              _vm.user.firstName && _vm.user.lastName
                                ? _c(
                                    "v-avatar",
                                    _vm._g(
                                      {
                                        staticClass: "white--text mt-4",
                                        staticStyle: {
                                          left: "36px"
                                        },
                                        attrs: {
                                          "data-test-user-menu": "",
                                          size: "45px",
                                          color: "primary"
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.user.firstName[0] || "") +
                                          _vm._s(_vm.user.lastName[0] || "") +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.user.firstName) +
                            " " +
                            _vm._s(_vm.user.lastName)
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        outlined: "",
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "align-with-title": ""
                          },
                          model: {
                            value: _vm.tab,
                            callback: function callback($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        [
                          _c("v-tabs-slider"),
                          _c("v-tab", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _vm.canCreateImage
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.image"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isSignEnabled && _vm.canCreateSign
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.signature"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isTemplateEnabled && _vm.canCreateMessage
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.email"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isDocumentUploadActive && _vm.canCreateDocument
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.upload"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isSharingEnabled && _vm.canShareContent
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.share"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.exportOptions.length > 0
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.export"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4 mr-4",
                              attrs: {
                                icon: "",
                                "x-small": ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.goToSettings()
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    dark: ""
                                  }
                                },
                                [_vm._v(" mdi-cog-outline ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function callback($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: "0"
                            },
                            [
                              _c("report-activity-box-comment-component", {
                                attrs: {
                                  report: _vm.report
                                }
                              })
                            ],
                            1
                          ),
                          _vm.canCreateImage
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "1"
                                },
                                [
                                  _c("report-activity-box-image-component", {
                                    attrs: {
                                      source: _vm.source,
                                      partner: _vm.partner
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSignEnabled && _vm.canCreateSign
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "2"
                                },
                                [
                                  _c("report-document-sign-request-form", {
                                    attrs: {
                                      report: _vm.report
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isTemplateEnabled && _vm.canCreateMessage
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "3"
                                },
                                [
                                  _c("report-activity-box-mail-component", {
                                    attrs: {
                                      report: _vm.report,
                                      partner: _vm.partner
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isDocumentUploadActive && _vm.canCreateDocument
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "4"
                                },
                                [
                                  _c("report-activity-box-document-component", {
                                    attrs: {
                                      report: _vm.report,
                                      partnerId: _vm.partner.id
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab-item",
                            {
                              key: "5"
                            },
                            [
                              _vm.isSharingEnabled && _vm.canShareContent
                                ? _c("report-activity-box-share-component", {
                                    attrs: {
                                      report: _vm.report
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.exportOptions.length > 0
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "6"
                                },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      attrs: {
                                        elevation: "0"
                                      }
                                    },
                                    _vm._l(_vm.exportOptions, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: index
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                float: "left"
                                              },
                                              attrs: {
                                                plain: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.openSendDialog(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" " + _vm._s(item) + " ")]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _c("send-to-ksr-dialog", {
                                    ref: "ksrDialog",
                                    attrs: {
                                      thirdPartySystem: _vm.thirdPartySystemKsr
                                    }
                                  }),
                                  _c("send-to-da-dialog", {
                                    ref: "daDialog",
                                    attrs: {
                                      thirdPartySystem: _vm.thirdPartySystemDa
                                    }
                                  }),
                                  _c("send-to-pdr-dialog", {
                                    ref: "pdrDialog"
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "div",
        {
          attrs: {
            id: _vm.MARKER_ID
          }
        },
        [_c("debug", [_vm._v(" " + _vm._s(_vm.MARKER_ID) + " ")])],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isImageDialogActive,
            hideLeft: true,
            hideRight: true,
            title: _vm.$t("timeLine.ActivityTimeLineItemImage.dialogTitle")
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isImageDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isImageDialogActive = $event
            }
          }
        },
        [
          _c("report-activity-box-image-component", {
            attrs: {
              source: _vm.source,
              partner: _vm.partner
            },
            on: {
              save: function save($event) {
                _vm.isImageDialogActive = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }