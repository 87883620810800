


















































































































































































































import PartnerManageStepper from "@/components/partner/PartnerManageStepper.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component, Vue } from "vue-property-decorator";
import { ManageStepperDescriptionTypes } from "@/store/enum/partner/manage-stepper-description-type.enum";
import { ManageStepperEnum } from "@/store/enum/partner/manage-stepper.enum";
import { emailRule } from "@/lib/rules/contactRule";
import { ManageStepperScreenTypes } from "@/store/enum/partner/manage-stepper-screen-types.enum";
import { IPartnerManageStepperStep } from "@/lib/interfaces/partner/partnerManageStepperStep.interface";
import { ManageStepperSetupSetting } from "@/lib/interfaces/partner/partnerManageStepperSetupSetting.interface";
import { DaSettingsKeys } from "@/store/enum/manage/da-setting-keys.enum";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { SettingModule } from "@/store/modules/setting.store";

@Component({
  components: {
    PartnerManageStepper,
    LayoutSimple
  }
})
export default class PartnerManageStepperDAView extends Vue {
  valid = false;

  resourcesLoading = false;
  eventTypesLoading = false;
  workshopServicesLoading = false;

  selectionResourceId = NaN;
  selectionEventTypeId = NaN;
  selectionWorkshopServiceId = NaN;
  selectionWorkshopServiceSignSignedId = NaN;
  selectionWorkshopServiceSignCreatedId = NaN;

  /**
   * steps of DA Stepper
   */
  get steps(): IPartnerManageStepperStep[] {
    return [
      { type: ManageStepperScreenTypes.DESCRIPTION, descriptionType: ManageStepperDescriptionTypes.DA_SETUP_ASSISTANT },
      { type: ManageStepperScreenTypes.SETUP, setupSettings: this.daCredentialSettings, context: ManageStepperEnum.DA },
      { type: ManageStepperScreenTypes.CREDENTIALS, context: ManageStepperEnum.DA },
      {
        type: ManageStepperScreenTypes.SLOT,
        context: ManageStepperEnum.DA,
        preHook: this.fetchDaConfig,
        postHook: this.saveDaConfig
      },
      { type: ManageStepperScreenTypes.CREDENTIALS, context: ManageStepperEnum.DA2 },
      { type: ManageStepperScreenTypes.DESCRIPTION, descriptionType: ManageStepperDescriptionTypes.END }
    ];
  }

  /**
   * settings that are used in DA setup step
   */
  get daCredentialSettings(): ManageStepperSetupSetting[] {
    return [
      {
        label: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.username",
        key: DaSettingsKeys.USERNAME,
        value: "",
        rules: [emailRule()],
        description: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.usernameDescription"
      },
      {
        key: DaSettingsKeys.PASSWORD,
        value: "",
        isPassword: true,
        label: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.password",
        description: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.passwordDescription"
      },
      {
        key: DaSettingsKeys.GROUP,
        value: "",
        label: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.group",
        description: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.groupDescription"
      },
      {
        key: DaSettingsKeys.CENTER,
        value: "",
        label: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.center",
        description: "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.centerDescription"
      }
    ];
  }

  get daResources() {
    return ExportModule.daResources?.resources;
  }

  get daEventTypes() {
    return ExportModule.daEventTypes?.eventTypes;
  }

  get daWorkshopServices() {
    return ExportModule.daWorkshopServices?.workshopServices;
  }

  async fetchDaConfig() {
    this.resourcesLoading = true;
    this.eventTypesLoading = true;
    this.workshopServicesLoading = true;

    try {
      await Promise.all([
        ExportModule.getDaResources(PartnerModule.partner._id).finally(() => (this.resourcesLoading = false)),
        ExportModule.getDaEventTypes(PartnerModule.partner._id).finally(() => (this.eventTypesLoading = false)),
        ExportModule.getDaWorkshopServices(PartnerModule.partner._id).finally(
          () => (this.workshopServicesLoading = false)
        )
      ]);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden.");
    }
  }

  async saveDaConfig() {
    SettingModule.setDaSetupInformation({
      username: SettingModule.daSetupInformation.username,
      password: SettingModule.daSetupInformation.password,
      center: SettingModule.daSetupInformation.center,
      group: SettingModule.daSetupInformation.group,
      resource: Number(this.selectionResourceId),
      eventType: Number(this.selectionEventTypeId),
      isAutoExport: SettingModule.daSetupInformation.isAutoExport,
      isAutoExportSignCreated: SettingModule.daSetupInformation.isAutoExportSignCreated,
      isAutoExportSignSigned: SettingModule.daSetupInformation.isAutoExportSignSigned,
      workshopService: Number(this.selectionWorkshopServiceId),
      workshopServiceSignSigned: Number(this.selectionWorkshopServiceSignSignedId),
      workshopServiceSignCreated: Number(this.selectionWorkshopServiceSignCreatedId)
    });
  }
}
