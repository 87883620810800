





















































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { IControlElements, ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { IReference } from "@/models/reference.entity";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import RefsSelected from "../utility/RefsSelected.vue";
import RefsUser from "../utility/RefsUser.vue";
import ThirdPartySystemSideCard from "@/components/third-party-system/ThirdPartySystemSideCard.vue";
import AssigneePreview from "../utility/AssigneePreview.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    ThirdPartySystemSideCard,
    RefsSelected,
    RefsUser,
    AssigneePreview,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ThirdPartySystemTable extends mixins(PermissionMixin) {
  readonly store = ThirdPartySystemModule;

  readonly TpsEnum = TpsEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  selectedItems!: IThirdPartySystem[];

  @Prop({ default: false })
  showVehicleHeader!: boolean;

  @Prop({ default: () => [] })
  refs!: IReference[];

  selectedItem: IThirdPartySystem | null = null;

  selectedItemForDelete: IThirdPartySystem | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  isThirdPartySystemCreateDialogActive = false;

  get selectedItemsLocal(): IThirdPartySystem[] {
    return this.selectedItems;
  }

  set selectedItemsLocal(value: IThirdPartySystem[]) {
    this.$emit("update:selectedItems", value);
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.thirdPartySystem.system"),
      value: "system",
      align: "center",
      width: "120px"
    });

    headers.push({
      text: $t("objects.thirdPartySystem.externalId"),
      value: "externalId",
      width: "150px"
    });

    headers.push({
      text: $t("objects.thirdPartySystem.refs"),
      value: "refs"
    });

    headers.push({
      text: $t("objects.thirdPartySystem.userId"),
      value: "userId",
      align: "center",
      width: "120px"
    });

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.created",
      align: "end"
    });

    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: IThirdPartySystem) => selectedItem.goToDetail(this.$router, false)
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  beforeMount() {
    ThirdPartySystemModule.setFilters([]);
  }

  setSelectedItem(item: IThirdPartySystem | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: IThirdPartySystem) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
