











import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import PartnerReportList from "@/components/partner/PartnerReportList.vue";
import PartnerReportListPaginated from "@/components/partner/PartnerReportListPaginated.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IReport } from "@/models/report.entity";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import { ExportModule } from "@/store/modules/export.store";
import { AllMessagesModule } from "@/store/modules/all-messages.store";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerReportList,
    PartnerReportDetail,
    PartnerReportListPaginated
  }
})
export default class PartnerReports extends PartnerFallbackMixin {
  loading = false;

  get partnerId() {
    return PartnerModule.partner._id;
  }

  mounted() {
    PartnerModule.setReport(null);
    this.trySetByRouteOrDefault();
  }

  async loadDetail(report: IReport) {
    this.$log.debug(`PartnerReports.loadDetail: ${report._id}`);

    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({
        name: "PartnerReportsDetailView",
        params: { reportId: report._id, partnerId: report.partnerId }
      });

      return;
    }

    try {
      this.loading = true;
      const promises: Promise<any>[] = [];

      promises.push(PartnerModule.getReportByIdForPartner(report._id));

      ActivityLogModule.setFilters([
        new PageFilterElement({
          key: "source.refId",
          operation: PageFilterOperationEnum.EQUAL,
          value: report._id
        })
      ]);
      promises.push(ActivityLogModule.fetchFirstPage({ partnerId: report.partnerId }));

      if (FeatureModule.isTpsActive) {
        ThirdPartySystemModule.setFilters([
          new PageFilterElement({
            key: "refs.refId",
            operation: PageFilterOperationEnum.EQUAL,
            value: PartnerModule.report._id
          })
        ]);
        promises.push(
          ThirdPartySystemModule.fetchFirstPage({ partnerId: report.partnerId }).catch(error => this.$log.error(error))
        );
      } else {
        promises.push(
          ExportModule.getExportReportMetaByPartnerIdAndReportId({
            partnerId: PartnerModule.report.partnerId,
            reportId: PartnerModule.report._id
          }).catch(error => this.$log.error(error))
        );
      }

      AllMessagesModule.generateFrontendTimeLineElementsForMessagesAssociatedWithReference({
        refId: PartnerModule.report._id,
        partnerId: PartnerModule.report.partnerId
      });

      await Promise.all(promises);
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async set(item: IReport) {
    await this.loadDetail(item);
  }
}
