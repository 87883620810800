






































































































































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import EventCreateDialog from "@/components/event/EventCreateDialog.vue";
import BlueprintCreateDialog from "@/components/fleet/BlueprintCreateDialog.vue";
import ContractCreateDialog from "@/components/fleet/ContractCreateDialog.vue";
import CreateMileageDialog from "@/components/fleet/CreateMileageDialog.vue";
import FleetCostTable from "@/components/fleet/FleetCostTable.vue";
import FleetHomeVehicleTableActions from "@/components/fleet/FleetHomeVehicleTableActions.vue";
import FleetHomeVehicleTableTicketSideCard from "@/components/fleet/FleetHomeVehicleTableTicketSideCard.vue";
import FleetVehicleCrudMixin from "@/components/fleet/FleetVehicleCrudMixin.vue";
import FleetVehicleDetailActivityCard from "@/components/fleet/FleetVehicleDetailActivityCard.vue";
import FleetVehicleDetailCard from "@/components/fleet/FleetVehicleDetailCard.vue.vue";
import FleetVehicleDetailContextMenu from "@/components/fleet/FleetVehicleDetailContextMenu.vue";
import FleetVehicleDetailContractTable from "@/components/fleet/FleetVehicleDetailContractTable.vue";
import FleetVehicleDetailDamageCard from "@/components/fleet/FleetVehicleDetailDamageCard.vue";
import FleetVehicleDetailDocumentCard from "@/components/fleet/FleetVehicleDetailDocumentCard.vue";
import FleetVehicleDetailEventCard from "@/components/fleet/FleetVehicleDetailEventCard.vue";
import FleetVehicleDetailHomeCard from "@/components/fleet/FleetVehicleDetailHomeCard.vue";
import FleetVehicleDetailTicketCard from "@/components/fleet/FleetVehicleDetailTicketCard.vue";
import FleetVehicleDetailVehicleBlueprintTableCard from "@/components/fleet/FleetVehicleDetailVehicleBlueprintTableCard.vue";
import FleetVehicleDetailVehicleCard from "@/components/fleet/FleetVehicleDetailVehicleCard.vue";
import FleetVehicleDetailVehicleMileageDataCard from "@/components/fleet/FleetVehicleDetailVehicleMileageDataCard.vue";
import FleetVehicleDriverDetailTable from "@/components/fleet/FleetVehicleDriverDetailTable.vue";
import FleetVehicleEventCrudMixin from "@/components/fleet/FleetVehicleEventCrudMixin.vue";
import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import PartnerReportInitializeDialog from "@/components/partner/PartnerReportInitializeDialog.vue";
import TicketCreateDialog from "@/components/project/TicketCreateDialog.vue";
import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { BaseGoToHelper, GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { ITicket } from "@/models/ticket.entity";
import { Vehicle } from "@/models/vehicle.entity";
import { MrfiktivReferenceGen, MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DriverModule } from "@/store/modules/driver.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { VehicleActivityLogModule } from "@/store/modules/activity-log.store";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import ContextMenu from "../../components/utility/ContextMenu.vue";
import ProjectInitializeListsMixin from "../project/mixins/ProjectInitializeListsMixin.vue";
import DocumentSelectionDialog from "../sign/DocumentSelectionDialog.vue";
import CreateHandoverDialog from "@/components/handover/CreateHandoverDialog.vue";
import HandoverTable from "@/components/handover/HandoverTable.vue";
import { PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";

export enum VehicleDetailActions {
  SHOW_DETAIL = "SHOW_DETAIL",
  DELETE = "DELETE",
  CREATE_REPORT = "CREATE_REPORT",
  CREATE_TICKET = "CREATE_TICKET",
  CREATE_EVENT = "CREATE_EVENT",
  CREATE_MILEAGE = "CREATE_MILEAGE",
  CREATE_CONTRACT = "CREATE_CONTRACT",
  CREATE_DOCUMENT = "CREATE_DOCUMENT",
  EDIT_VEHICLE = "EDIT_VEHICLE",
  EDIT_STATUS_VEHICLE = "EDIT_STATUS_VEHICLE",
  CREATE_COST = "CREATE_COST",
  CREATE_BLUEPRINT = "CREATE_BLUEPRINT",
  CREATE_HANDOVER = "CREATE_HANDOVER"
}

@Component({
  components: {
    TheLayoutPortal,
    LayoutSimple,
    Card,
    FleetVehicleDetailVehicleCard,
    FleetVehicleDetailDocumentCard,
    FleetVehicleDetailActivityCard,
    FleetVehicleDetailHomeCard,
    FleetVehicleDetailContractTable,
    FleetVehicleDetailVehicleMileageDataCard,
    FleetVehicleDetailVehicleBlueprintTableCard,
    LatestEntriesCardEmpty,
    ContextMenu,
    FleetVehicleDetailContextMenu,
    FleetVehicleDetailDamageCard,
    FleetVehicleDetailEventCard,
    FleetVehicleDetailTicketCard,
    FleetCostTable,
    FleetVehicleDetailCard,
    FleetHomeVehicleTableActions,
    FleetHomeVehicleTableTicketSideCard,
    PartnerReportDetailSideCard,
    MHeader,
    MDetailViewGrid,
    MDetailTable,
    MActionList,
    FleetVehicleDriverDetailTable,
    ConfirmActionDialog,
    TicketCreateDialog,
    PartnerReportInitializeDialog,
    EventCreateDialog,
    CreateMileageDialog,
    ContractCreateDialog,
    BlueprintCreateDialog,
    CreateHandoverDialog,
    DocumentSelectionDialog,
    EventSideCard: () => import("@/components/event/EventSideCard.vue"),
    CostCreateDialog,
    HandoverTable
  }
})
export default class FleetVehicleDetailView extends mixins(
  FleetVehicleCrudMixin,
  PartnerFallbackMixin,
  ProjectInitializeListsMixin,
  FleetVehicleEventCrudMixin,
  PermissionMixin
) {
  readonly VehicleTabs = VehicleTabs;
  readonly ActivityTypeEnum = ActivityTypeEnum;
  readonly VehicleStateEnum = VehicleStateEnum;

  loading = false;
  showActions = true;

  tab = 0;

  deleteReason = "";
  tempStatus = this.vehicle?.state ?? VehicleStateEnum.ACTIVE;

  focusedTicket: ITicket | null = null;
  focusedReport: IReport | null = null;
  focusedEvent: IEventUIDto | null = null;

  isReportSideCard = false;
  isReportSideCardLoading = false;
  isDeleteDialog = false;

  isTicketCreateDialogActive = false;

  /**
   * Displays mileage dialog
   */
  isMileageCreateDialogActive = false;

  /**
   * Displays contract dialog
   */
  isCreateContractDialogActive = false;

  /**
   * Displays document dialog
   */
  isCreateDocumentDialogActive = false;

  /**
   * Displays dialog to change th vehicle status
   */
  isUpdateVehicleStatusActive = false;

  /**
   * Opening create confirmation
   */
  eventCreate = {
    isDialogActive: false,
    isDialogLoading: false,
    isValid: false
  };

  isCostCreateDialogActive = false;

  get vehicleReferenceFilter(): IPageFilterElement {
    return new PageFilterElement({
      key: "refs.refId",
      operation: "$eq",
      value: this.vehicleId
    });
  }

  get vehicleStates() {
    return Object.values(VehicleStateEnum);
  }

  get vehicleId() {
    return this.$route.params.vehicleId;
  }

  get vehicle() {
    if (!this.vehicleAggregation?.vehicle) {
      return undefined;
    }

    return new Vehicle(this.vehicleAggregation.vehicle);
  }

  get refs(): MrfiktivReferenceGen[] {
    return [
      {
        refId: this.vehicleId,
        refType: ResourceEnum.VEHICLE
      }
    ];
  }

  get statusColor() {
    const state = this.vehicleAggregation?.vehicle?.state;
    if (!state) return "Grey";

    return VehicleModule.stateMap.get(state)?.color || "Grey";
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get tabs() {
    const items = [VehicleTabs.HOME];
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.MILEAGE, this.partnerId)) {
      items.push(VehicleTabs.MILEAGE);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.partnerId)) {
      items.push(VehicleTabs.REPORT);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, this.partnerId)) {
      items.push(VehicleTabs.EVENT);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TICKET, this.partnerId)) {
      items.push(VehicleTabs.TICKET);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.COST, this.partnerId)) {
      items.push(VehicleTabs.COST);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CONTRACT, this.partnerId)) {
      items.push(VehicleTabs.CONTRACT);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DOCUMENT, this.partnerId)) {
      items.push(VehicleTabs.DOCUMENT);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.BLUEPRINT, this.partnerId)) {
      items.push(VehicleTabs.BLUEPRINTS);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.HANDOVER, this.partnerId)) {
      items.push(VehicleTabs.HANDOVER);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ACTIVITY, this.partnerId)) {
      items.push(VehicleTabs.ACTIVITY);
    }

    return items;
  }

  get damages() {
    return ReportPaginationModule.paginationList;
  }

  get tableShowMoreAction() {
    return {
      text: $t("designGuide.MDetailForm.allProps"),
      key: VehicleDetailActions.SHOW_DETAIL
    };
  }

  get customFieldsForm() {
    return [];
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    if (PartnerModule.isTrain) {
      return [
        {
          key: "displayName",
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.TEXT
        },
        {
          key: "identificationnumber",
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.TEXT
        },
        {
          key: "note",
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.TEXT
        }
      ];
    }
    return [
      {
        key: "displayName",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "numberplate",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "identificationnumber",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "registrationDate",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        key: "registration.manufacturerName",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "registration.manufacturerType",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "note",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      }
    ];
  }

  get chips(): IAction[] {
    if (!this.vehicleAggregation?.vehicle?.tags) {
      return [];
    }

    return this.vehicleAggregation?.vehicle.tags.map(t => ({
      text: t,
      key: t
    }));
  }

  get breadCrumbs() {
    return [
      {
        text: this.$t("common.nouns.fleet"),
        exact: true,
        disabled: false,
        to: {
          name: "FleetHome",
          params: {
            partnerId: this.partnerId
          }
        }
      },
      {
        text: this.vehicleAggregation?.vehicle.displayName,
        exact: true,
        disabled: true,
        to: {
          name: "FleetVehicleDetail",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicleId
          }
        }
      }
    ];
  }

  /**
   * A list of actions to be displayed. Must match the @processAction function by key.
   * Displays actions based on users permissions in order of position in array.
   */
  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.VEHICLE, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-pencil",
        text: this.$t("views.fleet.actions.editVehicle").toString(),
        key: VehicleDetailActions.EDIT_VEHICLE,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.VEHICLE, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-delta",
        text: this.$t("views.fleet.actions.editStatusVehicle").toString(),
        key: VehicleDetailActions.EDIT_STATUS_VEHICLE,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.TICKET, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-ticket-outline",
        text: this.$t("views.fleet.actions.addTicket").toString(),
        key: VehicleDetailActions.CREATE_TICKET,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.REPORT, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-car-wrench",
        text: this.$t("views.fleet.actions.addReport").toString(),
        key: VehicleDetailActions.CREATE_REPORT,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.COST, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-cash-multiple",
        text: this.$t("cost.createCost").toString(),
        key: VehicleDetailActions.CREATE_COST,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.EVENT, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-calendar",
        text: this.$t("views.fleet.actions.addEvent").toString(),
        color: "",
        key: VehicleDetailActions.CREATE_EVENT
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.MILEAGE, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-map-marker-distance",
        text: this.$t("views.fleet.actions.addMileage").toString(),
        color: "",
        key: VehicleDetailActions.CREATE_MILEAGE
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.CONTRACT, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-signature",
        text: this.$t("views.fleet.actions.addContract").toString(),
        color: "",
        key: VehicleDetailActions.CREATE_CONTRACT
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.DOCUMENT, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-file",
        text: this.$t("views.fleet.actions.addDocument").toString(),
        color: "",
        key: VehicleDetailActions.CREATE_DOCUMENT
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.BLUEPRINT, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-drawing-box",
        text: this.$t("views.fleet.actions.createBlueprint").toString(),
        key: VehicleDetailActions.CREATE_BLUEPRINT,
        color: ""
      });
    }

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.HANDOVER, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-drawing-box",
        text: $t("views.fleet.actions.createHandover"),
        key: VehicleDetailActions.CREATE_HANDOVER,
        color: ""
      });
    }

    actions.push({
      text: this.$t("views.fleet.actions.showDetails").toString(),
      key: VehicleDetailActions.SHOW_DETAIL,
      icon: "mdi-code-tags"
    });

    if (UserModule.abilities.can(ActionEnum.DELETE, ResourceEnum.VEHICLE, PartnerModule.partner._id)) {
      actions.push({
        text: this.$t("views.fleet.actions.deleteVehicle").toString(),
        key: VehicleDetailActions.DELETE,
        icon: "mdi-delete-outline",
        color: "red"
      });
    }

    return actions;
  }

  async mounted() {
    this.setTab(this.$route.params.tab as VehicleTabs);
    this.partnerId = this.$route.params.partnerId;
    this.loading = true;

    await this.trySetByRouteOrDefault();

    try {
      const promises = [];

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, this.partnerId)) {
        promises.push(
          this.loadVehicle().then(() => {
            this.loading = false;
            if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.partnerId)) {
              this.loadDamages();
            }
          })
        );
      }
      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ACTIVITY, this.partnerId)) {
        promises.push(this.getActivities());
      }
      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DRIVER, this.partnerId)) {
        promises.push(this.loadDriver());
      }

      // permissions are handled inside method
      promises.push(this.initializeProjectLists(this.partnerId));

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, this.partnerId)) {
        promises.push(this.listOverdueEvents());
      }

      await Promise.all(promises);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  cancelChangeVehicleStatus() {
    this.isUpdateVehicleStatusActive = false;
    this.tempStatus = this.vehicle?.state ?? VehicleStateEnum.ACTIVE;
  }

  async changeVehicleStatus() {
    this.$log.debug("changeVehicleStatus", this.tempStatus);
    if (!this.vehicle) return;

    this.vehicle.state = this.tempStatus;
    await this.updateVehicleState(this.vehicle).finally(() => (this.isUpdateVehicleStatusActive = false));
  }

  @Watch("tab")
  syncRoute() {
    const location = this.$router.resolve({
      name: "VehicleDetailTab",
      params: {
        partnerId: this.$route.params.partnerId,
        vehicleId: this.$route.params.vehicleId,
        tab: this.tabs[this.tab] ?? ""
      }
    });
    if (location) {
      new BaseGoToHelper(this.$router).setUrl(location.location);
    }
  }

  async openEventSideCard(event: IEventUIDto) {
    this.focusedEvent = null;
    await event.createVirtual();
    this.$nextTick(() => {
      this.focusedEvent = event;
    });
  }

  setTab(tab?: VehicleTabs) {
    this.tab = 0;
    if (tab) {
      const index = this.tabs.indexOf(tab as VehicleTabs);

      if (index > -1) {
        this.tab = index;
      }
    }
  }

  /**
   * processes the action from @see actions function
   * @param action the action to be executed
   */
  processAction(action: IAction) {
    switch (action.key) {
      case VehicleDetailActions.SHOW_DETAIL:
        this.goToDetail();
        break;
      case VehicleDetailActions.DELETE:
        this.isDeleteDialog = true;
        break;
      case VehicleDetailActions.CREATE_REPORT:
        (this.$refs.PartnerReportInitializeDialog as PartnerReportInitializeDialog).initializeDialog();
        break;
      case VehicleDetailActions.CREATE_COST:
        (this.$refs.costCreateDialog as CostCreateDialog).show();
        break;
      case VehicleDetailActions.CREATE_EVENT:
        this.eventCreate.isDialogActive = true;
        (this.$refs.EventCreateDialog as EventCreateDialog).dialog = true;
        break;
      case VehicleDetailActions.CREATE_TICKET:
        this.isTicketCreateDialogActive = true;
        break;
      case VehicleDetailActions.CREATE_MILEAGE:
        this.isMileageCreateDialogActive = true;
        break;
      case VehicleDetailActions.CREATE_CONTRACT:
        this.isCreateContractDialogActive = true;
        break;
      case VehicleDetailActions.CREATE_DOCUMENT:
        this.isCreateDocumentDialogActive = true;
        break;
      case VehicleDetailActions.EDIT_STATUS_VEHICLE:
        this.isUpdateVehicleStatusActive = true;
        break;
      case VehicleDetailActions.CREATE_BLUEPRINT:
        (this.$refs.blueprintDialog as BlueprintCreateDialog).open();
        break;
      case VehicleDetailActions.CREATE_HANDOVER:
        //TODO
        break;
      case VehicleDetailActions.EDIT_VEHICLE:
        this.goToDetail();
        break;
    }
  }

  openTicketSideCard(ticket: ITicket) {
    this.focusedTicket = null;
    this.$nextTick(() => {
      this.focusedTicket = ticket;
    });
  }

  onTicketCreated(ticket: ITicket) {
    this.$emit("openTicketSideCard", ticket);
  }

  async loadDamages() {
    ReportPaginationModule.setSearch("");
    ReportPaginationModule.setFilters([
      new PageFilterElement({
        key: "vehicleId",
        value: this.vehicleId,
        operation: "$eq"
      })
    ]);
    await ReportPaginationModule.fetchAll({
      partnerId: PartnerModule.partner._id
    });
  }

  async goToDetail() {
    await this.$router.push({ name: "VehicleDetailView" });
  }

  async onBack() {
    await this.$router.push({
      name: "FleetHome",
      params: {
        partnerId: this.$route.params.partnerId
      }
    });
  }

  async getActivities() {
    try {
      VehicleActivityLogModule.setFilters([
        new PageFilterElement({
          key: "source.refId",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: this.vehicleId
        }),
        new PageFilterElement({
          key: "source.refType",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: BackendResourceEnum.VEHICLE
        })
      ]);
      await VehicleActivityLogModule.fetchFirstPage({
        partnerId: this.partnerId
      });
    } catch (e) {
      handleError(e);
    }
  }

  async listOverdueEvents() {
    try {
      await VehicleEventModule.listOverdue({
        partnerId: this.partnerId,
        vehicleId: this.vehicleId
      });
    } catch (e) {
      handleError(e);
    }
  }

  async loadDriver() {
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DRIVER, PartnerModule.partner._id)) {
      DriverModule.setFilter([]);
      DriverModule.setHiddenFilter([]);
      await DriverModule.getAll(PartnerModule.partner._id);
    }
  }

  async openReportSideCard(report: MrfiktivReportViewModelGen) {
    try {
      this.isReportSideCardLoading = false;
      this.isReportSideCard = true;

      this.focusedReport = null;

      const found = await PartnerModule.getReportByIdForPartner(report.id);

      this.focusedReport = found;
    } catch (e) {
      handleError(e);
    } finally {
      this.isReportSideCardLoading = false;
    }
  }

  async openContractDetail(contract: MrfiktivReportViewModelGen) {
    const vehicleId = this.vehicleAggregation?.vehicle.id;
    if (!vehicleId) {
      this.$log.error("no this.vehicleAggregation?.vehicle.id");
      return;
    }
    this.$router.push({
      name: "FleetContractDetailView",
      params: {
        partnerId: this.partnerId,
        vehicleId: vehicleId,
        contractId: contract.id
      }
    });
  }

  /**
   * Create Calender Event
   */
  async onCreateEvent(event: IEventUIDto) {
    if (!this.vehicleAggregation) {
      return;
    }

    await this.createEvent(event, this.vehicleAggregation.vehicle.id, new Date());
  }

  async goToEventEdit(event: IEventUIDto) {
    await event.createVirtual();

    if (!event.id) {
      this.$log.error("event id missing");
      return;
    }
    await new GoToHelper(this.$router).goToEventDetailEdit(this.partnerId, event.id, false);
  }

  async goToEventDetail(event: IEventUIDto) {
    await event.createVirtual();

    if (!event.id) {
      this.$log.error("event id missing");
      return;
    }
    await new GoToHelper(this.$router).goToEventDetail(this.partnerId, event.id, true);
  }

  async onDeleteEvent(event: IEventUIDto, close?: Function) {
    if (!event.vehicleId) {
      this.$log.error("No vehicleId");
      return;
    }

    await this.deleteEvent(event, event.vehicleId, new Date(event.start));

    this.focusedEvent = null;

    if (close) close();
  }

  async onUpdateEvent(event: IEventUIDto, close?: Function) {
    if (!event.vehicleId) {
      this.$log.error("No vehicleId");
      return;
    }

    await this.updateEvent(event, event.vehicleId, new Date(event.start));

    if (close) close();
  }
}
