














import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { VehicleActivityLogModule } from "@/store/modules/activity-log.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { TableWrapper, Card, ComboBox }
})
export default class FleetVehicleDetailActivityCard extends Vue {
  @Prop()
  vehicle!: IVehicle;

  search = "";

  get reference() {
    return {
      refType: BackendResourceEnum.VEHICLE,
      refId: this.vehicle.id
    };
  }

  get partnerId() {
    return this.vehicle.partnerId;
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailDocumentCard") || {};
  }

  readonly headers: ITableWrapperHeader[] = [
    { text: this.i18n["createdDate"], value: "timestamp.created", type: "date", align: "center", width: "150px" },
    { text: this.i18n["creator"], value: "name", width: "150px" },
    { text: this.i18n["type"], value: "activity", width: "150px" },
    { text: this.i18n["description"], value: "comment" }
  ];

  get allDocuments() {
    return VehicleActivityLogModule.filteredAndSorted;
  }
}
