var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-card",
        {
          attrs: {
            loading: _vm.loading,
            flat: "",
            outlined: "",
            tile: ""
          }
        },
        [
          _c(
            "template",
            {
              slot: "progress"
            },
            [
              _c("v-progress-linear", {
                attrs: {
                  color: _vm.loadingColor,
                  height: "3",
                  indeterminate: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              attrs: {
                src: _vm.headerUrl,
                gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                height: "200px"
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "white--text"
                    },
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "text-h5 mb-1"
                        },
                        [_vm._v(" " + _vm._s(_vm.partner.companyName) + " ")]
                      ),
                      _vm.url
                        ? _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "white--text"
                            },
                            [_vm._v(_vm._s(_vm.url))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.url
                    ? _c(
                        "v-list-item-avatar",
                        {
                          staticClass: "hidden-xs-only",
                          attrs: {
                            tile: "",
                            size: "120",
                            color: "white"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              ref: "download",
                              attrs: {
                                download: _vm.qrCodeName
                              },
                              on: {
                                click: _vm.downloadQrLink
                              }
                            },
                            [
                              _c("qrcode-vue", {
                                ref: "qrcode",
                                attrs: {
                                  value: _vm.url,
                                  size: "120",
                                  level: "L",
                                  "data-test-qrcode": "",
                                  id: "qr",
                                  background: "transparent"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-container",
        {
          attrs: {
            fluid: ""
          }
        },
        [
          _vm.$can(
            _vm.ActionEnum.READ,
            _vm.ResourceEnum.ANALYTICS,
            _vm.partnerId
          )
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        xs: "12",
                        sm: "12",
                        md: "4",
                        lg: "4",
                        xl: "4",
                        col: "12"
                      }
                    },
                    [
                      _c("analytics-date-range-selector-dialog", {
                        ref: "dateRange",
                        staticStyle: {
                          width: "100%"
                        },
                        attrs: {
                          dates: _vm.dates
                        },
                        on: {
                          setDate: _vm.setDateRange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$can(
            _vm.ActionEnum.READ,
            _vm.ResourceEnum.ANALYTICS,
            _vm.partnerId
          )
            ? _c(
                "v-row",
                {
                  staticClass: "match-height"
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "8"
                      }
                    },
                    [
                      _c("time-series-line-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.trendAnalysis.isLoading,
                          data: _vm.trendData,
                          title:
                            "views.portal.PartnerAnalytics.title.trendAnalysis"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "4"
                      }
                    },
                    [
                      _c("pie-chart-card", {
                        attrs: {
                          loading: _vm.dataHandler.statusDistribution.isLoading,
                          data: _vm.statusDistributionData,
                          title:
                            "views.portal.PartnerAnalytics.title.statusTypeDistribution"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "match-height"
            },
            [
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.REPORT,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("latest-report-entries-card", {
                        attrs: {
                          loading: _vm.loadingReport
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.FORM,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("latest-form-entries-card", {
                        attrs: {
                          loading: _vm.loadingForm
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.authenticatedPartnerUser.isArchived()
            ? _c("partner-user-missing-permissions-dialog")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }