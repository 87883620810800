

























import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ContractCard from "@/components/fleet/ContractCard.vue";
import FleetContractCrudMixin from "@/components/fleet/FleetContractCrudMixin.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DocumentModule } from "@/store/modules/document.store";
import { UserModule } from "@/store/modules/me-user.store";
import { VehicleActivityLogModule } from "@/store/modules/activity-log.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";

@Component({
  components: {
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    ContractCard
  }
})
export default class FleetContractDetailView extends mixins(FleetContractCrudMixin, PartnerFallbackMixin) {
  loading = false;

  loadingUpdate = false;

  vehicle: IVehicle | null = null;

  vehicleDocuments: ISignDocument[] = [];

  loadingDocumentSuggestions = false;

  contract: MrfiktivLeasingContractGen | null = null;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get vehicleId() {
    return this.$route.params.vehicleId;
  }

  get vehicleDocumentIds() {
    const documentIds: string[] = [];

    const activities = VehicleActivityLogModule.filteredAndSorted;
    for (const activity of activities) {
      if (activity.activity === ActivityTypeEnum.CREATE_DOCUMENT && activity.target?.length) {
        documentIds.push(activity.target[0].refId);
      }
    }

    return documentIds;
  }

  get title() {
    return this.$t("views.fleet.FleetContractDetailView.title");
  }

  async mounted() {
    await this.loadContract();
    await this.loadVehicleDocuments();
  }

  async loadVehicleDocuments() {
    if (!UserModule.abilities.can(ActionEnum.READ, BackendResourceEnum.DOCUMENT, this.partnerId)) return;

    try {
      VehicleActivityLogModule.setFilters([
        new PageFilterElement({
          key: "source.refId",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: this.vehicleId
        }),
        new PageFilterElement({
          key: "source.refType",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: BackendResourceEnum.VEHICLE
        })
      ]);

      await VehicleActivityLogModule.fetchFirstPage({ partnerId: this.partnerId });
      const activities = VehicleActivityLogModule.filteredAndSorted;
      const documentIds: string[] = [];
      for (const activity of activities) {
        if (activity.activity === ActivityTypeEnum.CREATE_DOCUMENT && activity.target?.length) {
          documentIds.push(activity.target[0].refId);
        }
      }

      this.vehicleDocuments.splice(0);
      const promises = [];
      this.loadingDocumentSuggestions = true;

      for (const id of this.vehicleDocumentIds) {
        const vehicleDocument = DocumentModule.maps.id.get(id)[0];
        if (vehicleDocument) {
          this.vehicleDocuments.push(vehicleDocument);
        } else {
          promises.push(
            new SignDocument({ partnerId: this.partnerId, id })
              .fetch()
              .then(d => {
                this.vehicleDocuments.push(d);
              })
              .catch(this.$log.error)
          );
        }
      }
      await Promise.all(promises);
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingDocumentSuggestions = false;
    }
  }

  async loadContract() {
    try {
      this.loading = true;
      await this.trySetByRouteOrDefault();
      const vehicle = await VehicleModule.getOne({
        partnerId: this.partnerId,
        vehicleId: this.vehicleId
      });
      this.vehicle = vehicle;

      this.contract =
        (this.vehicle?.contracts as MrfiktivLeasingContractGen[]).find(
          (c: MrfiktivLeasingContractGen) => c.id === this.$route.params.contractId
        ) ?? null;
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }

  async onUpdateContract(contract: MrfiktivLeasingContractGen) {
    this.$log.debug("onUpdateContract", contract);
    await this.updateContract(this.partnerId, this.vehicleId, contract.id, contract);
  }

  async onDeleteContract(contract: MrfiktivLeasingContractGen) {
    await this.deleteContract(this.partnerId, this.vehicleId, contract.id);
    this.contract = null;
  }
}
