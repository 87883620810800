














































import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { handleError } from "@/lib/utility/handleError";
import { ActivityLog } from "@/models/activity-log.entity";
import { Blueprint, IBlueprint } from "@/models/blueprint.entity";
import { IVehicle } from "@/models/vehicle.entity";
import {
  MrfiktivBlueprintElementViewmodelGen,
  MrfiktivCreateActivityLogDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ImageDialog from "../utility/ImageDialog.vue";
import BlueprintCreateDialog from "./BlueprintCreateDialog.vue";
import { IActivity } from "@/models/caseEntity";

@Component({
  components: { TableWrapper, BlueprintCreateDialog, ConfirmActionDialog, ImageDialog }
})
export default class FleetVehicleDetailVehicleBlueprintTableCard extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  loading!: boolean;

  deleteBlueprint: IBlueprint | undefined;

  deleteLoading = false;

  blueprintDialog = false;

  removeBlueprintDialog = false;

  selected = [];

  async remove() {
    try {
      this.deleteLoading = true;
      await this.deleteBlueprint?.delete();

      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType: BackendResourceEnum.VEHICLE,
          refId: this.vehicle.id
        },
        target: [],
        actionType: ActionEnum.DELETE,
        activity: ActivityTypeEnum.DELETE_BLUEPRINT,
        comment: this.deleteBlueprint?.image.name
      };
      await new ActivityLog({ partnerId: this.vehicle.partnerId, ...data }).create();
    } catch (error) {
      handleError(error);
    } finally {
      this.removeBlueprintDialog = false;
      this.deleteBlueprint = undefined;
      this.deleteLoading = false;
    }
  }

  abortRemoval() {
    this.removeBlueprintDialog = false;
    this.deleteBlueprint = undefined;
  }

  openDeleteDialog(bluePrint: MrfiktivBlueprintElementViewmodelGen) {
    this.deleteBlueprint = new Blueprint(this.vehicle.partnerId, this.vehicle.id, bluePrint);
    this.removeBlueprintDialog = true;
  }

  get controlElements(): IControlElements[] {
    return [{ icon: "mdi-trash-can", action: this.openDeleteDialog, text: this.$t("delete").toString() }];
  }

  get headers(): ITableWrapperHeader[] {
    return [
      { text: "", value: "image.url", placeholder: "" },
      { text: this.$t("blueprint.name").toString(), value: "name", placeholder: "" },
      { text: this.$t("blueprint.type").toString(), value: "type", placeholder: "" },
      { text: this.$t("blueprint.description").toString(), width: "300px", value: "description", placeholder: "" },
      { text: this.$t("blueprint.location").toString(), width: "300px", value: "location", placeholder: "" },
      { text: "", align: "end", value: "controls", sortable: false }
    ];
  }

  onCreatedActivity(activity: IActivity) {
    this.$emit("createdActivity", activity);
  }

  openBlueprintDialog() {
    (this.$refs.blueprintDialog as BlueprintCreateDialog).open();
  }

  emitRefresh() {
    this.$emit("refresh");
  }

  emitUpdate() {
    this.$emit("change", this.vehicle);
  }
}
