


























































































































































import MDetailForm from "@/components/utility/mmmint/MDetailForm.vue";
import { ContractTypeEnum } from "@/lib/enum/contractType.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerModule } from "@/store/modules/partner";
import DocumentSelectionDialog from "@/views/sign/DocumentSelectionDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import ContractCardDocument from "./ContractCardDocument.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ISignDocument } from "@/models/sign-document.entity";

@Component({
  components: {
    LatestEntriesCardEmpty,
    MHeader,
    MDetailForm,
    DocumentSelectionDialog,
    ConfirmActionDialog,
    MDetailViewGrid,
    MDetailTable,
    MActionList,
    ActivityCard,
    TemplateEditor,
    ContractCardDocument
  },
  filters: {}
})
export default class ContractCard extends mixins(RulesMixin, PermissionMixin) {
  @Prop()
  value!: MrfiktivLeasingContractGen;

  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  vehicle!: IVehicle;

  @Prop()
  vehicleId!: string;

  @Prop({ default: true })
  hideBreadcrumbs!: boolean;

  @Prop({ default: true })
  hideToDetail!: boolean;

  @Prop({ default: () => [] })
  documentSuggestions!: ISignDocument[];

  @Prop()
  loadingDocumentSuggestions!: boolean;

  readonly TO_DETAIL_DETAIL = "EdDet";

  readonly TO_DETAIL_KEY = "DetKey";

  readonly DELETE_KEY = "DelKey";

  readonly ContractTypeEnum = ContractTypeEnum;

  readonly ActivityTypeEnum = ActivityTypeEnum;

  descriptionEditorKeys = 0;

  isEditDescription = false;

  descriptionCopy = "";

  loadingDocuments = false;

  documentPenal: number[] = [];

  documentIds: string[] = [];

  documentForDeletion: ISignDocument | null = null;

  isConfirmDeleteDialogActive = false;

  isConfirmDeleteContractDialogActive = false;

  height = document.documentElement.clientHeight;

  isValid = true;

  startDateCopy = "";

  endDateCopy = "";

  addDocumentDialog = false;

  get startDate() {
    if (!this.startDateCopy) return "";
    return new Date(this.startDateCopy).toISOString();
  }

  get readableStartDate() {
    return simpleDoubleDigitDate(this.startDate);
  }

  get endDate() {
    if (!this.endDateCopy) return "";
    return new Date(this.endDateCopy).toISOString();
  }

  get readableEndDate() {
    return simpleDoubleDigitDate(this.endDate);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get customFieldsForm() {
    return [];
  }

  /**
   * Returns the detail table configuration for the contract
   * add properties that are defined, not part of header and simple data types
   */
  get detailTableConfig(): IMDetailTableConfig[] {
    const fields: IMDetailTableConfig[] = [];
    let counter = 0;
    for (const key in this.value) {
      if (counter >= 10) break;

      if (
        !this.value[key] ||
        ["id", "title", "startDate", "endDate", "description"].includes(key) ||
        !(typeof this.value[key] === "string" || typeof this.value[key] === "number")
      )
        continue;

      fields.push({
        key: key,
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      });
      counter++;
    }
    return fields;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.CONTRACT
    };
  }

  get reference() {
    if (!(this.vehicle || this.vehicleId)) return undefined;

    return {
      refId: this.vehicle?.id ?? this.vehicleId,
      refType: BackendResourceEnum.VEHICLE
    };
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs") || {};
  }

  protected get baseCrumbs() {
    return [
      {
        text: $t("common.nouns.fleet").toString(),
        exact: true,
        disabled: false,
        to: {
          name: "FleetHome",
          params: {
            partnerId: this.partnerId
          }
        }
      },
      {
        text: this.vehicle.displayName || this.vehicle.numberplate || this.vehicle.id || this.vehicleId,
        exact: true,
        disabled: false,
        to: {
          name: "FleetVehicleDetailTab",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicle?.id ?? this.vehicleId,
            tab: VehicleTabs.CONTRACT
          }
        }
      }
    ];
  }

  get breadCrumbs() {
    if (this.hideBreadcrumbs) return undefined;

    return [
      ...this.baseCrumbs,
      {
        text: this.$t("common.nouns.contract").toString(),
        exact: true,
        disabled: true,
        to: {
          name: "FleetContractDetailView",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicle?.id ?? this.vehicleId,
            contractId: this.value.id
          }
        }
      }
    ];
  }

  get tableShowMoreAction() {
    return {
      text: $t("common.verbs.edit"),
      key: this.TO_DETAIL_DETAIL
    };
  }

  get chips(): IAction[] | undefined {
    const chips: IAction[] = [];

    const isExpired = this.endDateCopy && new Date(this.endDateCopy) < new Date();
    if (isExpired) {
      chips.push({
        text: $t("common.states.expired").toString(),
        color: "red",
        key: "expired"
      });
    }

    const isUpcoming = this.startDateCopy && new Date(this.startDateCopy) > new Date();
    if (isUpcoming) {
      chips.push({
        text: this.$t("common.states.upcoming").toString(),
        color: "info",
        key: "contract"
      });
    }

    const isActive =
      // we can only determine if active if there is start or end
      (this.startDateCopy || this.endDateCopy) &&
      // case 1: Start and end date are given, start date is in the past and end date is in the future
      ((this.startDateCopy &&
        this.endDateCopy &&
        new Date(this.startDateCopy) <= new Date() &&
        new Date(this.endDateCopy) >= new Date()) ||
        // case 2: there is no start date and end date is in the future
        (!this.startDateCopy && new Date(this.endDateCopy) >= new Date()) ||
        // case 3: there is no end date and start date is in the past
        (!this.endDateCopy && new Date(this.startDateCopy) <= new Date()));

    if (isActive) {
      chips.push({
        text: $t("common.states.active").toString(),
        color: "success",
        key: "active"
      });
    }

    if (!chips.length) return undefined;

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];
    if (!this.hideToDetail) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: this.TO_DETAIL_KEY,
        icon: "mdi-open-in-new",
        color: ""
      });
    }

    actions.push({
      text: $t("common.verbs.edit"),
      key: this.TO_DETAIL_DETAIL,
      icon: "mdi-open-in-new",
      color: ""
    });

    actions.push({
      text: $t("common.verbs.delete"),
      key: this.DELETE_KEY,
      icon: "mdi-trash-can",
      color: "red"
    });

    return actions;
  }

  beforeMount() {
    this.startDateCopy = this.value.startDate ?? "";
    this.endDateCopy = this.value.endDate ?? "";
    this.descriptionCopy = this.value.description ?? "";
    this.descriptionEditorKeys++;

    this.value.refs?.forEach(ref => {
      if (ref.refType === BackendResourceEnum.DOCUMENT) {
        this.documentIds.push(ref.refId);
      }
    });
  }

  processAction(action: { text: string; key: string }) {
    switch (action.key) {
      case this.TO_DETAIL_KEY:
        new GoToHelper(this.$router).goToContractDetail(
          this.partnerId,
          this.vehicle?.id ?? this.vehicleId,
          this.value.id,
          true
        );
        break;
      case this.TO_DETAIL_DETAIL:
        new GoToHelper(this.$router).goToContractDetailDetail(
          this.partnerId,
          this.vehicle?.id ?? this.vehicleId,
          this.value.id,
          false
        );
        break;
      case this.DELETE_KEY:
        this.isConfirmDeleteContractDialogActive = true;
        break;
    }
  }
  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.descriptionEditorKeys++;
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.descriptionEditorKeys++;
    this.isEditDescription = false;
  }

  saveEditDesciption() {
    this.$emit("save", { ...this.value, description: this.descriptionCopy });
    this.isEditDescription = false;
  }

  deleteContract() {
    this.isConfirmDeleteContractDialogActive = false;
    this.$emit("delete", this.value);
    new GoToHelper(this.$router).goToVehicleDetail(
      this.vehicle?.id ?? this.vehicleId,
      this.partnerId,
      VehicleTabs.CONTRACT,
      false
    );
  }

  initiateFileUpload() {
    this.addDocumentDialog = true;
  }

  deleteReference(documentId: string) {
    this.$emit("save", {
      ...this.value,
      refs: this.value.refs?.filter(d => d.refId !== documentId) ?? []
    });

    this.documentIds = this.documentIds.filter(d => d !== documentId);
    this.isConfirmDeleteDialogActive = false;
  }

  async addReferenceToContract(document: ISignDocument) {
    this.$emit("save", {
      ...this.value,
      refs: [
        ...(this.value.refs ?? []),
        {
          refId: document.id,
          refType: BackendResourceEnum.DOCUMENT
        }
      ]
    });

    this.documentIds.push(document.id);
  }
}
